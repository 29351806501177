import { Button, ScreenReaderContent, Text, TextInput } from '@instructure/ui';

const MainSearchBoxForm = ({
  buttonLabel,
  onSearchQueryChange,
  onSubmit,
  searchInstructions,
  searchQuery,
  searchType,
}) => (
  <form onSubmit={onSubmit}>
    <Text
      data-testid={`${searchType}-search-instructions`}
      as="p"
      size="medium"
    >
      {searchInstructions}
    </Text>
    <TextInput
      onChange={onSearchQueryChange}
      renderLabel={<ScreenReaderContent>Search query</ScreenReaderContent>}
      value={searchQuery}
      margin="x-small"
      display="block"
      type="search"
    />
    <Button
      margin="x-small"
      color="primary"
      type="submit"
      interaction={
        searchQuery && searchQuery.trim() !== '' ? 'enabled' : 'disabled'
      }
    >
      {buttonLabel}
    </Button>
  </form>
);

export default MainSearchBoxForm;
