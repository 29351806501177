const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const MapContainer = ({ address }) => {
  return (
    <div id="mapdiv">
      <iframe
        title="mapframe"
        style={{ width: '100%', height: '400px', frameborder: '0' }}
        src={
          'https://www.google.com/maps/embed/v1/place?q=' +
          encodeURIComponent(address) +
          '&zoom=17&key=' +
          API_KEY
        }
      ></iframe>
    </div>
  );
};

export default MapContainer;
