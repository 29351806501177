import { Heading, View } from '@instructure/ui';

const SyllabusResources = () => {
  return (
    <div className="help-pages-container">
      <View>
        <div>
          <Heading margin="0 0 medium" level="h2">
            Syllabus Resources
          </Heading>
        </div>
        <div>
          <Heading level="h3">Faculty of Arts and Science</Heading>
          <div>
            <Heading level="h4">
              Derek Bok Center for Teaching and Learning
            </Heading>

            <ul>
              <li>
                <a href="https://bokcenter.harvard.edu/syllabus-design">
                  Syllabus Design
                </a>
                : Key functions and components of a syllabus; framing modules by
                putting assignments first; questions to ask of your syllabus
              </li>
              <li>
                <a href="https://docs.google.com/document/d/1paKx3mtqy_PDycGk0FfcGJQjdv5nHfDrdGMLmrFJTfE/edit?usp%3Dsharing">
                  Syllabus Template
                </a>
              </li>
            </ul>
          </div>
          <div>
            <Heading level="h4">
              Information for Faculty Offering Instruction in the Arts and
              Sciences
            </Heading>

            <ul>
              <li>
                <a href="https://infoforfaculty.fas.harvard.edu/book/course-materialssyllabi">
                  Course Materials/Syllabi
                </a>
                : Guidelines on syllabus content and design
              </li>
            </ul>
          </div>
          <div>
            <Heading level="h4">Harvard College</Heading>

            <ul>
              <li>
                <a href="https://honor.fas.harvard.edu/syllabus-design">
                  Sample statements on academic integrity, collaboration, and
                  course goals and teaching philosophies
                </a>
              </li>
            </ul>
          </div>
          <div>
            <Heading level="h4">Program in General Education</Heading>

            <ul>
              <li>
                <a href="https://generaleducation.fas.harvard.edu/syllabus-policies">
                  Syllabus &amp; Course Policies
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <Heading level="h3">Harvard School of Public Health</Heading>
          <div>
            <Heading level="h4">Office of Education</Heading>

            <ul>
              <li>
                <a href="https://www.hsph.harvard.edu/office-of-education/teaching-support-and-learning-strategies/syllabus-and-course-design">
                  Syllabus Design &amp; Requesting Course Materials
                </a>
              </li>
              <li>
                <a href="https://cdn1.sph.harvard.edu/wp-content/uploads/sites/2096/2017/02/CEP-General-Syllabus-Template-002.docx">
                  Syllabus Template (.docx)
                </a>{' '}
                (adopted by the Committee on Educational Policy)
              </li>
            </ul>
          </div>
        </div>
        <div>
          <Heading level="h3">Harvard Kennedy School</Heading>
        </div>
        <div>
          <Heading level="h4">
            SLATE (Strengthening Learning and Teaching Excellence)
          </Heading>

          <ul>
            <li>
              <a href="https://www.hks.harvard.edu/more/about/leadership-administration/academic-deans-office/slate/teaching-resources/planning-course">
                Building a Syllabus
              </a>{' '}
              (bottom of page)
            </li>
            <li>
              <a href="https://www.hks.harvard.edu/sites/default/files/2017-05/Course_Syllabus_Template.doc">
                Syllabus Template (.doc)
              </a>
            </li>
          </ul>

          <div>
            HKS faculty are invited to{' '}
            <a href="https://knet.hks.harvard.edu/Administration/Academic-Deans-Office/Case-Program/Teaching-Resources/Pages/Planning-a-Course.aspx">
              visit KNet
            </a>{' '}
            (password required) for more information on preparing and posting
            syllabi and course materials.
          </div>
        </div>
        <div>
          <Heading level="h3">Harvard Graduate School of Design</Heading>
        </div>
        <div>
          <Heading level="h4">Faculty Affairs Office</Heading>

          <ul>
            <li>
              <a href="https://www.gsd.harvard.edu/resources/faculty-affairs/faculty-handbooks">
                Faculty Handbooks &amp; Guidelines for Instruction
              </a>{' '}
              (in Guidelines for Instruction)
            </li>
          </ul>
        </div>
        <div>
          <Heading level="h3">Harvard Graduate School of Education</Heading>

          <div>
            For questions related to GSE syllabi, including specific information
            on policies and norms, please contact{' '}
            <a href="mailto:learning@gse.harvard.edu">
              learning@gse.harvard.edu
            </a>
            .
          </div>
        </div>
        <div>
          <Heading level="h3">Harvard Divinity School</Heading>

          <div>
            For questions related to HDS syllabi, please contact contact the
            applicable{' '}
            <a href="https://hds.harvard.edu/offices/academic-affairs">
              faculty support team
            </a>{' '}
            in the Office of Academic Affairs.
          </div>
        </div>
        <div>
          <Heading level="h3">General Resources</Heading>

          <div>
            <Heading level="h4">
              Vanderbilt University Center for Teaching
            </Heading>

            <ul>
              <li>
                <a href="https://cft.vanderbilt.edu/guides-sub-pages/syllabus-design">
                  Syllabus Design
                </a>
                : What does a syllabus contain? How does tone affect learners?
                Policy &amp; resource examples (e.g., academic integrity,
                personal electronic devices, inclusivity &amp; diversity, etc.)
              </li>
            </ul>
          </div>
          <div>
            <Heading level="h4">
              University of Virginia Center for Teaching Excellence
            </Heading>

            <ul>
              <li>
                <a href="https://cte.virginia.edu/resources/syllabus-rubric">
                  Syllabus Rubric
                </a>
                : Provides qualitative descriptions of components that
                distinguish learning-focused syllabi and uses a quantitative
                scoring system that places syllabi on a spectrum from
                content-focused to learning-focused.
              </li>
            </ul>
          </div>
          <div>
            <Heading level="h4">
              University of California-Berkeley Center for Teaching &amp;
              Learning
            </Heading>

            <ul>
              <li>
                <a href="https://teaching.berkeley.edu/news/what-does-your-syllabus-say-about-learning">
                  What Does Your Syllabus Say About Learning?
                </a>{' '}
                6 strategies to move students past just "getting something
                right"
              </li>
            </ul>
          </div>
          <div>
            <Heading level="h4">
              J. Parkes and M.B. Harris, College Teaching 50.2 (2002): 55-61
            </Heading>

            <ul>
              <li>
                <a href="http://jan.ucc.nau.edu/~coesyl-p/syllabus_cline_article_2.pdf">
                  The Purposes of a Syllabus (.pdf)
                </a>
                : Syllabus as a contract, a permanent record, and as a learning
                tool
              </li>
            </ul>
          </div>
        </div>
      </View>
    </div>
  );
};
export default SyllabusResources;
