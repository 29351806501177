import { useEffect, useState } from 'react';
import { clearAuth, getJWT } from './auth';

import * as Sentry from '@sentry/react';

import { deepEqual } from '@instructure/ui-utils';

export const baseUrl = process.env.REACT_APP_API_URL;
const AUTH_HEADER = 'Authorization';
const PAGE_SIZE = 10;

export const API_ERROR = {
  COURSE_NOT_FOUND: 'course_not_found',
  RESOURCE_NOT_FOUND: 'resource_not_found',
  UNAUTHORIZED: 'unauthorized',
};

export const urls = {
  course: `${baseUrl}/course`,
  download: `${baseUrl}/download/`,
  feedback: `${baseUrl}/send_feedback/`,
  search: {
    courses: `${baseUrl}/search`,
    departments: `${baseUrl}/search/depts`,
    similar: `${baseUrl}/search/similar`,
  },
  schools: `${baseUrl}/schools`,
};

/* returns an object which maps department IDs to objects containing department details */
export const getDepartments = schoolList => {
  const deptDict = {};
  schoolList.forEach(s => {
    s.depts.forEach(d => {
      deptDict[d.id] = d;
    });
  });
  return deptDict;
};

export const getSchoolFromDeptId = deptId => deptId?.split('--', 1)?.pop();

// this is a placeholder for a standard fetch flow
// which could include logging, merging with context, etc.
const handleFetch = response => response.json();

const getFixedToken = () => process.env.REACT_APP_LOCAL_API_TOKEN;
const getAuthToken = process.env.REACT_APP_LOCAL_API_TOKEN
  ? getFixedToken
  : getJWT;

// todo: errors should somehow signal to calling functions to settle into graceful error state

export const useSchoolsFetch = isAuthenticated => {
  const [results, setResults] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    // only fetch schools once, at app load; in effect, cache the schools
    // API endpoint response for the lifetime of the app
    if (isAuthenticated && Array.isArray(results) && results.length === 0) {
      setIsFetching(true);
      fetchSchools().then(
        result => {
          setResults(result);
          setIsFetching(false);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        error => {
          console.log('Error:', error.message);
          setIsFetching(false);
          setError(error); // allow components to re-throw for ErrorBoundary
        },
      );
    }
  }, [isAuthenticated, results]);
  return { results, isFetching, error };
};

const getAuthenticatedRequest = url => {
  // todo: mode:'same-origin'? https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
  return sendAuthenticatedRequest(url);
};

const postAuthenticatedRequest = (url, body) => {
  const jsonBody = typeof body === 'string' ? body : JSON.stringify(body);
  return sendAuthenticatedRequest(url, { method: 'POST', body: jsonBody });
};

const sendAuthenticatedRequest = (url, initOptions) => {
  // todo: mode:'same-origin'? https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
  return new Request(url, {
    credentials: 'omit',
    headers: { [AUTH_HEADER]: getAuthToken() },
    ...initOptions,
  });
};

const callFetch = async request => {
  let transaction = Sentry.getCurrentHub().getScope().getTransaction();
  if (!transaction) {
    /* The Sentry react/js integration only starts transactions for certain events:
      - pageload: a fresh browser request
      - navigation: a change in `history` (page URL state change)
      When the most recent managed pageload/navigation event has settled Sentry will
      finish() and flush it, and further XHR calls will not be traced automatically.
      Hence, if there is no current transaction, we need to start one if we want to
      trace it. We call its initial `op` 'idle-client-fetch' to distinguish it from
      other sentry-integration-managed transactions.
    */
    const urlPath = new URL(request.url).pathname;
    transaction = Sentry.startTransaction({
      name: `fetch ${urlPath}`,
      op: 'idle-client-fetch',
    });
    // Set transaction on scope to associate with errors and get included span instrumentation
    // (note that actual fetch calls are instrumented by Sentry -- a span with
    // `span.op=http.client` will automatically be created as a child of this transaction)
    Sentry.getCurrentHub().configureScope(scope => scope.setSpan(transaction));
  }

  return fetch(request).then(response => {
    // finish transaction conditionally if it is initiated just for this request
    // and not by the Sentry integration automatically (based on the transaction.op above)
    if (transaction?.op === 'idle-client-fetch') {
      transaction.finish();
    }
    if (response.ok) return response;
    if (response.status === 403) {
      console.log(
        'Response: unauthorized 403; clearing authentication to try again',
      );
      clearAuth();
      throw new Error(API_ERROR.UNAUTHORIZED);
    }
    if (response.status === 404) {
      throw new Error(API_ERROR.RESOURCE_NOT_FOUND);
    }
    throw new Error(`response ${response.status}`);
  });
};

const fetchSchools = async () => {
  const request = getAuthenticatedRequest(urls['schools']);
  const response = await callFetch(request);
  return handleFetch(response);
};

const fetchCourseDetail = async queryObject => {
  const urlParams = new URLSearchParams(queryObject).toString();
  const url = `${urls['course']}?${urlParams}`;
  const request = getAuthenticatedRequest(url);
  let response;
  try {
    response = await callFetch(request);
  } catch (error) {
    if (error.message === API_ERROR.RESOURCE_NOT_FOUND)
      throw new Error(API_ERROR.COURSE_NOT_FOUND);
    throw error;
  }
  return handleFetch(response);
};

export const useCoursesFetch = (query, filters, page) => {
  return useSearchFetch({ query }, filters, 'courses', page);
};
// note: departments fetch does not support/require pagination
export const useDepartmentsFetch = (query, filters) =>
  useSearchFetch({ query }, filters, 'departments');
export const useSimilarCoursesFetch = (docId, filters, page) => {
  return useSearchFetch({ doc_id: docId }, filters, 'similar', page);
};

const search = async (queryObj, searchType, filters, page = 0) => {
  const requestBody = {
    filters: {
      dept_ids: filters.deptFilter ? [filters.deptFilter] : [],
      school_ids: filters.schoolFilter ? [filters.schoolFilter] : [],
      when: filters.whenFilter || 'anytime',
    },
    size: PAGE_SIZE,
    from: page * PAGE_SIZE,
    ...queryObj,
  };
  const url = urls['search'][searchType];
  const searchRequest = postAuthenticatedRequest(url, requestBody);
  const response = await callFetch(searchRequest);
  return handleFetch(response);
};

const useSearchFetch = (queryObj, filters, searchType, page = 0) => {
  const [currentFilters, setCurrentFilters] = useState(filters);
  const [currentPage, setCurrentPage] = useState(-1);
  const [currentQueryObj, setCurrentQueryObj] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const isNewQuery =
      !deepEqual(queryObj, currentQueryObj) ||
      !deepEqual(filters, currentFilters);
    const isNewPage = page !== currentPage;
    if ((queryObj.query || queryObj.doc_id) && (isNewQuery || isNewPage)) {
      if (isNewQuery) {
        setCurrentQueryObj(queryObj);
        setResults([]);
        setCurrentPage(0);
        setCurrentFilters(filters);
      }
      if (isNewPage) setCurrentPage(page);
      setIsFetching(true);
      const fetchPage = isNewQuery ? 0 : page;
      search(queryObj, searchType, filters, fetchPage).then(
        result => {
          setIsFetching(false);
          setResults(prevResults => prevResults.concat(result.hits));
          setHasMore(result.total > (fetchPage + 1) * PAGE_SIZE);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        error => {
          console.log('Error:', error.message);
          setError(error); // allow components to re-throw for ErrorBoundary
          setIsFetching(false);
          setHasMore(false);
        },
      );
    }
  }, [
    currentFilters,
    currentPage,
    currentQueryObj,
    error,
    filters,
    hasMore,
    isFetching,
    page,
    queryObj,
    results,
    searchType,
  ]);

  return { results, isFetching, error, hasMore };
};

export const useMyHarvardCourseDetailFetch = myHarvardCourseId =>
  useGenericCourseDetailFetch({ myHarvardCourseId: myHarvardCourseId });

export const useCourseDetailFetch = courseDocId =>
  useGenericCourseDetailFetch({
    docId: courseDocId,
  });

export const useGenericCourseDetailFetch = ({ docId, myHarvardCourseId }) => {
  const [currentDocId, setCurrentDocId] = useState('');
  const [currentMyHarvardCourseId, setCurrentMyHarvardCourseId] = useState('');
  const [results, setResults] = useState({});
  const [isFetching, setFetch] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    if (docId || myHarvardCourseId) {
      const isNewQuery =
        currentDocId !== docId ||
        currentMyHarvardCourseId !== myHarvardCourseId;
      if (isNewQuery) {
        const queryObj = docId
          ? { doc_id: docId }
          : { my_h_id: myHarvardCourseId };
        setCurrentDocId(docId);
        setCurrentMyHarvardCourseId(myHarvardCourseId);
        setResults({});
        setFetch(true);
        fetchCourseDetail(queryObj).then(
          result => {
            setResults(result);
            setFetch(false);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          error => {
            console.log('Error:', error.message);
            setFetch(false);
            setError(error); // allow components to re-throw for ErrorBoundary
          },
        );
      } else {
        setFetch(false);
        setResults({});
      }
    }
  }, [currentDocId, currentMyHarvardCourseId, docId, myHarvardCourseId]);
  return { results, isFetching, error };
};

export const fetchDownloadLink = async ({
  s3KeyPrefix,
  termName,
  courseId,
  courseCode,
}) => {
  const request = postAuthenticatedRequest(urls['download'], {
    s3KeyPrefix,
    termName,
    courseId,
    courseCode,
  });
  const response = await callFetch(request);
  const responseObj = await handleFetch(response);
  return responseObj.url;
};

export const submitFeedback = async (email, messageBody) => {
  const request = postAuthenticatedRequest(urls['feedback'], {
    email: email,
    message: messageBody,
  });
  const response = await callFetch(request);
  const responseObj = await handleFetch(response);
  return responseObj;
};
