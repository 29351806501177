import { useEffect, useState } from 'react';
import { fetchDownloadLink } from '../api';
import { logDownloadEvent } from '../analyticsTracker';

import {
  Button,
  CloseButton,
  FormFieldGroup,
  Heading,
  ScreenReaderContent,
  Spinner,
  Text,
  View,
} from '@instructure/ui';

import { generateLinkQueryId } from '../ui';

import CourseSchoolDetailLine from './CourseSchoolDetailLine';
import CourseTitleLine from './CourseTitleLine';
import DetailViewBackToResultsButton from './DetailViewBackToResultsButton';
import LoadingSpinner from './LoadingSpinner';
import ResponsiveMatch from './ResponsiveMatch';
import TermResultsList from './TermResultsList';
import ExternalLink from './ExternalLink';
import CourseDescription from './CourseDescription';

const VALID_FILE_EXTENSIONS = ['PDF', 'DOC', 'DOCX'];

const buildLabelName = (termName, myHId, courseCode, s3FileKey) => {
  const eventLabel =
    termName + ':' + myHId + ':' + courseCode + ':' + s3FileKey;
  return eventLabel.replace(/\s+/g, '_');
};

const getFileExtensionInfo = filename => {
  if (typeof filename === 'string') {
    const possibleExtension = filename.split('.').pop().toUpperCase();
    if (VALID_FILE_EXTENSIONS.includes(possibleExtension))
      return ` (${possibleExtension})`;
  }
  return '';
};

const CourseDetail = ({
  courseDetail,
  courseDetailLoading,
  onSubmitViewSimilarCourses,
  showSimilar = true,
  onClose,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const courseDetailId = courseDetail?.doc_id;
  const instance =
    courseDetail?.instances && courseDetail?.instances[selectedIndex];

  // show most recent term/instance details after loading a new course
  useEffect(() => {
    setSelectedIndex(0);
  }, [courseDetailId]);

  const handleSubmitViewSimilarCourses = e => {
    onSubmitViewSimilarCourses(e);
    e.preventDefault();
  };

  const [isFetchingDownload, setIsFetchingDownload] = useState(false);
  const handleSubmitDownloadSyllabus = async e => {
    e.preventDefault();
    setIsFetchingDownload(true);
    const fileUrl = await fetchDownloadLink({
      s3KeyPrefix: instance.s3_filekey,
      termName: instance.term_name,
      courseId: instance.my_h_id,
      courseCode: courseDetail.course_code,
    });
    window.location.assign(fileUrl);
    const eventLabel = buildLabelName(
      instance.term_name,
      instance.my_h_id,
      courseDetail.course_code,
      instance.s3_filekey,
    );
    logDownloadEvent(eventLabel);
    setIsFetchingDownload(false);
  };

  const handleTermSelect = termInstanceId => {
    setSelectedIndex(
      courseDetail.instances.findIndex(
        // Note: pre-2021 API returns canvas_course_id while 2021+ API
        // returns instance_id; this supports both but the canvas_course_id
        // can be removed once the old API is retired
        i =>
          (i?.instance_id || i?.canvas_course_id)?.toString() ===
          termInstanceId,
      ),
    );
  };

  if (
    courseDetailLoading ||
    (courseDetail && Object.entries(courseDetail).length === 0)
  ) {
    return LoadingSpinner;
  } else if (!courseDetail) {
    return (
      <View textAlign="center" display="block" padding="medium">
        <Text as="p" fontStyle="italic" weight="light">
          Choose a course to view details and download the syllabus.
        </Text>
      </View>
    );
  }

  return (
    <View
      as="div"
      className="selectedCourse"
      display="block"
      height="100%"
      overflowY="auto"
      padding="medium"
      position="relative"
      textAlign="start"
    >
      <ResponsiveMatch
        small={
          <DetailViewBackToResultsButton onClick={onClose}>
            &lt; Go back to {!showSimilar && 'similar '}course search results
          </DetailViewBackToResultsButton>
        }
        large={
          <CloseButton
            size="small"
            color="primary"
            placement="end"
            offset="small"
            onClick={onClose}
            screenReaderLabel="Close"
            interaction="enabled"
          ></CloseButton>
        }
      />
      <div id="course-detail-header">
        <Heading level="h4">Course Details:</Heading>
      </div>
      <div id="detail-view-course-title">
        <CourseTitleLine
          courseCode={courseDetail.course_code}
          courseTitle={courseDetail.course_title}
        />
        <CourseSchoolDetailLine
          schoolName={courseDetail.school_name}
          deptName={courseDetail.dept_name}
          programName={courseDetail.program_name}
        />
      </div>
      <TermResultsList
        instances={courseDetail.instances}
        // Note: pre-2021 API returns canvas_course_id while 2021+ API
        // returns instance_id; this supports both but the canvas_course_id
        // can be removed once the old API is retired
        selectedId={
          courseDetail.instances[selectedIndex].canvas_course_id ||
          courseDetail.instances[selectedIndex].instance_id
        }
        onRowClick={handleTermSelect}
        isSimilarResults={!showSimilar}
      />
      <Heading id="course-detail-term-heading" level="h4">
        {instance?.term_name} Details:
      </Heading>
      {instance?.my_h_id && <Text as="p">Course #{instance.my_h_id}</Text>}
      {instance?.all_instructor_names && (
        <>
          <Heading level="h5" id="course-detail-instructor-heading">
            <Text weight="bold">Instructor(s):</Text>
          </Heading>
          <Text as="p">{instance.all_instructor_names}</Text>
        </>
      )}
      <form onSubmit={handleSubmitDownloadSyllabus} id="download-syllabus-form">
        <FormFieldGroup
          layout="stacked"
          rowSpacing="medium"
          description={
            <ScreenReaderContent>Download syllabus form</ScreenReaderContent>
          }
          label="label"
        >
          <Button
            id="download-btn"
            size="small"
            color="primary"
            align="left"
            type="submit"
            interaction={
              instance.s3_filekey && !isFetchingDownload
                ? 'enabled'
                : 'disabled'
            }
          >
            {isFetchingDownload && (
              <Spinner
                renderTitle="Busy"
                size="x-small"
                margin="0 x-small 0 medium"
              />
            )}
            {instance.s3_filekey &&
              (isFetchingDownload ? 'Downloading' : 'Download')}{' '}
            {`${instance.term_name} Syllabus`}
            {getFileExtensionInfo(instance.s3_filekey)}
            {instance.s3_filekey ? '' : ' unavailable'}
          </Button>
        </FormFieldGroup>
      </form>
      <Text as="p">
        For up-to-date schedule and registration information,{' '}
        <ExternalLink href={generateLinkQueryId(instance.my_h_id)}>
          find this course on my.harvard
        </ExternalLink>
        .
      </Text>
      <Heading level="h5">
        <Text weight="bold">Description:</Text>
      </Heading>
      <CourseDescription>{instance?.description}</CourseDescription>
      {showSimilar && (
        <form onSubmit={handleSubmitViewSimilarCourses}>
          <FormFieldGroup
            layout="stacked"
            rowSpacing="medium"
            description={
              <ScreenReaderContent>
                Show similar courses form
              </ScreenReaderContent>
            }
            label="label"
          >
            <Button size="small" color="secondary" align="left" type="submit">
              View Similar Courses
            </Button>
          </FormFieldGroup>
        </form>
      )}
    </View>
  );
};

export default CourseDetail;
