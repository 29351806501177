import { mergeDeep } from '@instructure/ui-utils';

const MY_HARVARD_URL =
  'https://portal.my.harvard.edu/psp/hrvihprd/EMPLOYEE/EMPL/h/?tab=HU_CLASS_SEARCH&SearchReqJSON=%7B%22PageNumber%22%3A1%2C%22PageSize%22%3A%22%22%2C%22SortOrder%22%3A%5B%22IS_SCL_SUBJ_CAT%22%2C%22IS_SCL_SUBJ_CAT%22%5D%2C%22Facets%22%3A%5B%5D%2C%22Category%22%3A%22HU_SCL_SCHEDULED_BRACKETED_COURSES%22%2C%22SearchPropertiesInResults%22%3Atrue%2C%22FacetsInResults%22%3Atrue%2C%22SaveRecent%22%3Afalse%2C%22TopN%22%3A%22%22%2C%22DeepLink%22%3Afalse%2C%22SearchText%22%3A%22';

export const UI_PAGE_SEARCH_RESULTS = 0,
  UI_PAGE_RESULT_DETAILS = 1;

export const CANVAS_URL = 'https://canvas.harvard.edu';
export const CONTACT_US_LINK_TEXT = 'Contact Us';
export const HARVARD_LINK_URL = 'https://link.harvard.edu';
export const HELP_LINK_TEXT = 'Search Tips & FAQs';
export const RESOURCES_LINK_TEXT = 'Syllabus Resources';

const filterLabelsTemplate = {
  deptFilter: {
    caption: 'Department',
    display: {
      any: 'Any Department',
    },
  },
  schoolFilter: {
    caption: 'School',
    display: {
      any: 'Any School',
    },
  },
  whenFilter: {
    caption: 'Term',
    display: {
      anytime: 'Any Term',
      now: 'Current Term',
      past: 'Past Term',
      future: 'Future Term',
    },
  },
};

/* returns an object mapping department IDs to their display names */
export const getDepartmentNames = departmentLookup => {
  const deptNameDict = {};
  for (const deptId in departmentLookup) {
    deptNameDict[deptId] = departmentLookup[deptId].name;
  }
  return deptNameDict;
};

/* returns an object mapping filter names (e.g. schoolFilter) to objects conforming
  to `filterLabelsTemplate`, defined above, and augments the default filter values
  (e.g. "any") with dynamic information, if available */
export const getFilterLabels = ({ deptLabels = {}, schoolLabels = {} }) => {
  const filterLabels = mergeDeep({}, filterLabelsTemplate);
  filterLabels.deptFilter.display = mergeDeep(
    filterLabels.deptFilter.display,
    deptLabels,
  );
  filterLabels.schoolFilter.display = mergeDeep(
    filterLabels.schoolFilter.display,
    schoolLabels,
  );
  return filterLabels;
};

/* getInstanceScoresLookupForDocId() takes a list of search results along with
  a particular course docId, and returns an object mapping all of its terms'
  canvas course IDs to their respective relevance/similarity scores; this can
  be used to supplement course details to show scores for each term in the
  term list for that course.
  e.g. returns `{ 12345: 1, 67890: 0.78 }`
  */
export const getInstanceScoresLookupForDocId = (results, docId) => {
  const instances =
    (results.find(r => r.doc_id === docId) || {}).instances || [];
  return (
    // Note: pre-2021 API returns canvas_course_id while 2021+ API
    // returns instance_id; this supports both but the canvas_course_id
    // can be removed once the old API is retired
    instances
      .filter(i => i.canvas_course_id || i.instance_id)
      .reduce(
        (f, i) => ({ ...f, [i.canvas_course_id || i.instance_id]: i.score }),
        {},
      )
  );
};

/* returns an object mapping school IDs to their display names */
export const getSchoolNames = schoolList => {
  const schoolDict = {};
  schoolList.forEach(s => {
    schoolDict[s.id] = s.name;
  });
  return schoolDict;
};

export const generateLinkQueryId = my_h_id => {
  return MY_HARVARD_URL + my_h_id + '%22%7D';
};

const deiSearchQuery =
  'Ableism or Accessibility or "Affirmative action" or "Antiracist" or "Anti-racist" "Anti‐Semitism" or "Anti-Defamation" or Belonging or Bisexual or Biracial or Bigotry or Biphobia or "Black Lives Matter" or Classism or Cisgender or "Civil liberties" or Disability or Disabled or Discrimination or "Diversity and inclusion" or Equality or Ethnicity or Ethnic or Emigrate or Emigration or "First gen" or "First generation" or "First nations people" or Gay or Gender or Genderqueer or "Hate Crime" or Heterosexual or Homosexual or Homophobia or Immigration or "Imposter syndrome" or Implicit Bias or Intersex or Intersectionality or "Inclusion and belonging" or Indigenous or Islamophobia or LatinX or Lesbian or LGBT or LGBTQ or Marginalized or "Mental health" or Microaggression or Migrants or Minority or Multiracial or Multiethnic or Neurodiversity or "Non-binary" or Oppression or Pansexual or "People of color" or Prejudice or Pronouns or Polyamory or Queer or Racism or Race or "Racial justice" or "Religious freedom" or "Safe space" or Segregation or "Sexual Orientation" or Socioeconomic or "Social Responsibility" or "Social Identity" or "Social justice" or Stereotype or Suicide or Tolerance or Transgender or Transsexual or Transphobia or "Unconscious bias" or "White supremacy" or Xenophobia';

export const topics = {
  dei: '/search/courses/?q=' + encodeURIComponent(deiSearchQuery),
};
