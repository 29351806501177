import { CloseButton, Tray, View } from '@instructure/ui';

const QueryTray = ({ children, isOpen, onClose }) => (
  <div id="query-tray">
    <Tray
      label="Search Details"
      mountNode={document.getElementById('query-tray')}
      open={isOpen}
      onDismiss={onClose}
      size="small"
      placement="start"
      shouldCloseOnDocumentClick={true}
    >
      <View height="100%" id="search-bar" as="div" padding="medium">
        {children}
        <CloseButton
          placement="end"
          offset="small"
          screenReaderLabel="Close"
          onClick={onClose}
        />
      </View>
    </Tray>
  </div>
);

export default QueryTray;
