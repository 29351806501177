import * as Sentry from '@sentry/browser';

import { Component } from 'react';
import { login } from './auth';
import { API_ERROR } from './api';

// this is basically the example provided at https://reactjs.org/docs/error-boundaries.html
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { error: error };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error);
  }

  render() {
    if (this.state.error === null) {
      return this.props.children;
    }

    let message;
    switch (this.state.error.message) {
      case API_ERROR.UNAUTHORIZED:
        login();
        return null;
      case API_ERROR.COURSE_NOT_FOUND:
        message =
          "The course you are searching for is not available. It's possible that the course is new and thus not yet tracked by our systems, or that the link is out-of-date or invalid.";
        break;
      default:
        message =
          'There was an unexpected problem. Please reload to try again. If the error persists please contact your local support team.';
    }
    return (
      <div id="error-boundary">
        <h1>Hmmm.</h1>
        <p id="error">{message}</p>
      </div>
    );
  }
}
