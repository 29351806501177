import { canvas as canvasTheme } from '@instructure/ui-themes';

/* our custom overrides; these should match App.css for consistency,
    as we do need CSS rules in addition to theming in order to get
    the exact visual styles we want; for instance, visited links are
    not automatically styled by our theme, so must be styled using
    separate CSS rules
*/
const huColorValues = {
  backgroundLight: 'whitesmoke',
  brand: '#31748b',
  link: '#31748b',
};

/* there are a number of places we need to override the theme values
    to ensure all components get the exact visual styles we want --
    the canvasTheme object can be inspected at runtime to see its
    structure, or the code generating the theme can be consulted
*/
const backgroundColorOverrides = {
  backgroundBrand: huColorValues.brand,
  backgroundBrandSecondary: huColorValues.backgroundLight,
  backgroundInfo: huColorValues.brand,
};

const borderColorOverrides = {
  borderBrand: huColorValues.brand,
  borderInfo: huColorValues.brand,
};

const textColorOverrides = {
  textBrand: huColorValues.brand,
  textInfo: huColorValues.brand,
  textLink: huColorValues.link,
};

const valuesColorOverrides = {
  brand: huColorValues.brand,
  link: huColorValues.link,
};

const baseColorOverrides = {
  ...backgroundColorOverrides,
  ...borderColorOverrides,
  ...textColorOverrides,
  ...valuesColorOverrides,
};

const baseThemeOverrides = {
  'ic-brand-primary': huColorValues.brand,
  'ic-link-color': huColorValues.brand,
  'ic-brand-button--primary-bgd': huColorValues.brand,
};

/* use default colors from canvas theme, and override with our custom prefs */
const theme = {
  ...canvasTheme,
  ...baseThemeOverrides,
  colors: {
    ...canvasTheme.colors,
    ...baseColorOverrides,
    background: {
      ...canvasTheme.colors.background,
      ...backgroundColorOverrides,
    },
    border: { ...canvasTheme.colors.border, ...borderColorOverrides },
    text: { ...canvasTheme.colors.text, ...textColorOverrides },
    values: { ...canvasTheme.colors.values, ...valuesColorOverrides },
  },
  // rename our theme to prevent confusion with canvas theme
  key: 'syllabus-explorer-based-on-canvas',
};

export default theme;
