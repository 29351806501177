import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { EmotionThemeProvider } from '@instructure/emotion';

import './index.css';
import { baseUrl as apiBaseUrl } from './api';
import App from './App';
import theme from './theme';
import * as serviceWorker from './serviceWorker';

const history = createBrowserHistory();

// use `REACT_APP_SENTRY_DSN=""` in .env, .env.local, etc. to disable Sentry error reporting (and tracing) entirely
const SENTRY_DSN =
  process.env.REACT_APP_SENTRY_DSN?.trim() ??
  // default DSN is for https://sentry.io/organizations/harvard-university-academic-te/projects/syllabus-explorer/?project=5891853
  'https://4cfeb85981024cb0ab837c8c24014a46@o291188.ingest.sentry.io/5891853';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: (process.env.REACT_APP_ENV || 'unknown').toLowerCase(),
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      tracingOrigins: [apiBaseUrl],
    }),
  ],
  tracesSampleRate: parseFloat(
    process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || 0,
  ),
});

ReactDOM.render(
  <EmotionThemeProvider theme={theme}>
    <App history={history} />
  </EmotionThemeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
