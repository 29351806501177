import { CondensedButton } from '@instructure/ui';
import React, { useState } from 'react';

import CustomTruncateText from './CustomTruncateText';

const DEFAULT_CHAR_LIMIT = 500;

const CourseDescription = ({ children: text }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (text?.length <= DEFAULT_CHAR_LIMIT) return text;

  const toggle = e => {
    e.preventDefault();
    setIsExpanded(prevState => !prevState);
  };

  if (!text) {
    return (
      <p className="course-description">
        <span>
          <i>{'Description not available.'}</i>
        </span>
      </p>
    );
  }

  if (isExpanded)
    return (
      <p className="course-description">
        <span>{text}</span>{' '}
        <CondensedButton size="small" className="toggle" onClick={toggle}>
          show less
        </CondensedButton>
      </p>
    );

  return (
    <p className="course-description">
      <CustomTruncateText charLimit={DEFAULT_CHAR_LIMIT}>
        {text}
      </CustomTruncateText>{' '}
      <CondensedButton size="small" className="toggle" onClick={toggle}>
        read more
      </CondensedButton>
    </p>
  );
};

export default CourseDescription;
