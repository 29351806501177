import { Heading, ToggleGroup, View } from '@instructure/ui';

import ExternalLink from './ExternalLink';

const Help = () => {
  return (
    <div className="help-pages-container">
      <div>
        <Heading margin="0 0 medium" level="h2">
          Help with search
        </Heading>
      </div>
      <ToggleGroup
        toggleLabel="Search Operators"
        summary={<Heading level="h4">Search Operators</Heading>}
      >
        <View display="block" padding="small">
          <div>
            <dl>
              <dt>genome editing</dt>
              <dd>
                either of the words <i>genome</i> and <i>editing</i>, including
                variations of each such as <i>genomes</i> and <i>edit</i>
              </dd>

              <dt>+genome +editing</dt>
              <dd>
                both of the words <i>genome</i> and <i>editing</i>, including
                variations
              </dd>

              <dt>"genome editing"</dt>
              <dd>
                the exact phrase <i>genome editing</i>
              </dd>

              <dt>genome -editing</dt>
              <dd>
                the word <i>genome</i> but not <i>editing</i>
              </dd>

              <dt>genome -"genome editing"</dt>
              <dd>
                the word <i>genome</i> but not the phrase <i>genome editing</i>
              </dd>

              <dt>biotech*</dt>
              <dd>
                any word beginning with <i>biotech</i>
              </dd>
            </dl>
          </div>
        </View>
      </ToggleGroup>
      <ToggleGroup
        toggleLabel="Filtering Searches"
        summary={<Heading level="h4">Filtering Searches</Heading>}
      >
        <View display="block" padding="small">
          <div>
            <p>
              Use the <b>Left Menu</b> to subset the search results by year,
              school, and department. Result listings, including similar
              courses, have similar filtering features.
            </p>
          </div>
        </View>
      </ToggleGroup>
      <ToggleGroup
        toggleLabel="Advanced Search Features"
        summary={<Heading level="h4">Advanced Features</Heading>}
      >
        <View display="block" padding="small">
          <div>
            <h5>Fuzzy matching</h5>
            To match both <i>hematology</i> and <i>haematology</i>, search{' '}
            <b>hematology~</b> (with the trailing tilde).
            <h5>Boosting</h5>
            <p>
              Parts of your query can be weighted higher than others. For
              instance, search <b>"global health" nutrition^2</b> to find
              courses about global health that often feature the word{' '}
              <i>nutrition</i>.
            </p>
            <h5>Grouping</h5>
            All of these described search features can be used within groups.
            For instance, <b>+(politics OR policy) +health</b> will match
            records containing both <i>health</i> and either <i>politics</i> or{' '}
            <i>policy</i>.
          </div>
        </View>
      </ToggleGroup>
      <div>
        <Heading margin="0 0 medium" level="h2">
          Frequently asked questions (FAQs)
        </Heading>
      </div>
      <ToggleGroup
        toggleLabel="Using the site"
        summary={<Heading level="h4">Using the site</Heading>}
      >
        <View display="block" padding="small">
          <div>
            <h5>Why doesn't the course I'm looking at have a syllabus?</h5>
            <div>
              The Syllabus Explorer finds a syllabus by searching a course's
              Canvas site. If the Canvas site does not have a syllabus or if the
              instructor has uploaded the syllabus in such a way that the
              Syllabus Explorer cannot find it, the course record within the
              Syllabus Explorer will not contain a syllabus download link.
            </div>
            <h5>
              Where can I find additional course scheduling information about a
              class?
            </h5>
            <div>
              <ExternalLink
                href="https://my.harvard.edu"
                title="Harvard Course Catalog"
              >
                my.harvard
              </ExternalLink>{' '}
              is the best place to view up-to-date course scheduling and
              registration information. When available, we provide a link to a
              course's my.harvard record in the "Course details" section.
            </div>
            <h5>Can I save courses or add them to my Crimson Cart?</h5>
            <div>
              You cannot save courses or add them to your Crimson Cart from the
              Syllabus Explorer.
            </div>
            <h5>Why do I get an error when I click on a my.harvard link?</h5>
            <div>
              This is a known bug within my.harvard. Try clicking on the link
              again or, if that does not work,{' '}
              <ExternalLink
                href="https://harvard.service-now.com/ithelp?id=kb_article&sys_id=e9225537db854704a914fff31d961941"
                title="How to Remove my.harvard Website Data from Your Browser"
              >
                clearing your browser's cache and cookies
              </ExternalLink>
              .
            </div>
          </div>
        </View>
      </ToggleGroup>
      <ToggleGroup
        toggleLabel="Data sources"
        summary={<Heading level="h4">Data sources</Heading>}
      >
        <View display="block" padding="small">
          <div>
            <h5>How many syllabi are available on the Syllabus Explorer?</h5>
            <div>
              There are currently over 10,000 syllabi available on the Syllabus
              Explorer, dating back to the 2015-16 academic year.
            </div>
            <h5>How does the Syllabus Explorer find syllabi?</h5>
            <div>
              The Syllabus Explorer first looks for a syllabus in a Canvas
              site's designated syllabus field. The Syllabus Explorer will
              automatically discover syllabi uploaded as text, a file (e.g.
              PDF), or a link. Additionally, if a syllabus is uploaded outside
              of the syllabus section in Canvas, the Syllabus Explorer will
              attempt to find it by examining file names.
            </div>
            <h5>How frequently is data on the site updated?</h5>
            <div>
              Enrollment and syllabus information is updated periodically during
              the fall and spring semesters. Note that when an update is
              initiated, syllabi that have very recently been posted to Canvas
              might not be captured. This is due to processing times associated
              with how Canvas exports its data. News and events are updated
              daily through direct connections to the data sources.
            </div>
            <h5>How far back does the course and syllabi data go?</h5>
            <div>
              Syllabus Explorer data goes back to academic year 2015-2016. Note
              that at that time not all courses were on Canvas and not all had
              syllabi made available via Canvas. More recent courses are more
              likely to have syllabi available.
            </div>
          </div>
        </View>
      </ToggleGroup>
      <ToggleGroup
        toggleLabel="For instructors"
        summary={<Heading level="h4">For instructors</Heading>}
      >
        <View display="block" padding="small">
          <div>
            <h5>How can I add my syllabus to the Syllabus Explorer?</h5>
            <div>
              The Syllabus Explorer will periodically update its database of
              syllabi. To ensure that your syllabus will be found during the
              next update, please upload your syllabus to your course's Canvas
              site in the syllabus section.
            </div>
            <div>Before uploading your syllabus to Canvas, ensure that:</div>
            <div>
              <ol>
                <li>
                  The file has "syllabus" (case insensitive) in its filename
                  (required).
                </li>
                <li>
                  The file availability is set to "publish" not unpublished,
                  locked, deleted, or scheduled to be available in the future.
                </li>
                <li>
                  The file is a PDF, HTML file, or Word document with the
                  correct file extension (e.g., .pdf, .html, etc.).
                </li>
                <li>
                  The Canvas course site is "Published" and not "Concluded".
                </li>
              </ol>
            </div>
            <div>
              For additional assistance with Canvas, please contact your
              school's academic technology group or submit a ticket to{' '}
              <ExternalLink
                href="mailto:ithelp@harvard.edu"
                title="Send an email to Harvard IT help"
              >
                ithelp@harvard.edu
              </ExternalLink>
              .
            </div>
            <h5>How can I remove my syllabus from the Syllabus Explorer?</h5>
            <div>
              To remove your syllabus, please{' '}
              <ExternalLink
                href="https://icommons-tools.tlt.harvard.edu/qualtrics_taker_auth/?qtarget=aHR0cHM6Ly9oYXJ2YXJkLmF6MS5xdWFsdHJpY3MuY29tL2pmZS9mb3JtL1NWXzRIajViRTVXc0xEVHFteA=="
                title="Request to remove your syllabus from the Syllabus Explorer"
              >
                submit this form
              </ExternalLink>
              .
            </div>
          </div>
        </View>
      </ToggleGroup>
      <ToggleGroup
        toggleLabel="Other questions"
        summary={<Heading level="h4">Other questions</Heading>}
      >
        <View display="block" padding="small">
          <div>
            Please contact us at{' '}
            <ExternalLink
              href="mailto:syllabusexplorer@harvard.edu"
              title="Send an email to the Syllabus Explorer maintainers"
            >
              syllabusexplorer@harvard.edu
            </ExternalLink>
            .
          </div>
        </View>
      </ToggleGroup>
    </div>
  );
};
export default Help;
