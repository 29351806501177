import { Link } from 'react-router-dom';

import {
  Flex,
  Heading,
  IconButton,
  IconQuestionSolid,
  Menu,
} from '@instructure/ui';

import FeedbackModal from './FeedbackModal';
import { useNavigateTo } from '../navigation';
import {
  CONTACT_US_LINK_TEXT,
  HELP_LINK_TEXT,
  RESOURCES_LINK_TEXT,
} from '../ui';

const TopBar = ({ isContactModalOpen, setIsContactModalOpen }) => {
  const openContactUsModal = () => setIsContactModalOpen(true);

  return (
    <Flex justifyItems="center" as="div">
      <Flex.Item shouldGrow as="div">
        <div id="top-bar" className="headerbar">
          <FeedbackModal
            isOpen={isContactModalOpen}
            setIsOpen={setIsContactModalOpen}
          />
          <Flex height="4rem" justifyItems="center" className="headerbar">
            <Flex.Item shouldGrow shouldShrink padding="x-small">
              <Heading>
                <Link id="top-bar-title" to={'/'}>
                  Harvard Syllabus Explorer
                </Link>
              </Heading>
            </Flex.Item>
            <Flex.Item padding="x-small">
              <div id="help-menu">
                <Menu
                  withArrow={false}
                  mountNode={() => document.getElementById('help-menu')}
                  label={'Help, resources, and contact us'}
                  trigger={
                    <IconButton
                      color="primary-inverse"
                      screenReaderLabel="Help, resources, and contact us"
                      withBackground={false}
                      withBorder={false}
                    >
                      <IconQuestionSolid size="small" inline={false} />
                    </IconButton>
                  }
                >
                  <Menu.Item onClick={useNavigateTo('/help')}>
                    {HELP_LINK_TEXT}
                  </Menu.Item>
                  <Menu.Item onClick={useNavigateTo('/resources')}>
                    {RESOURCES_LINK_TEXT}
                  </Menu.Item>
                  <Menu.Item onClick={openContactUsModal}>
                    {CONTACT_US_LINK_TEXT}
                  </Menu.Item>
                </Menu>
              </div>
            </Flex.Item>
          </Flex>
        </div>
      </Flex.Item>
    </Flex>
  );
};

export default TopBar;
