import { InlineList, Text } from '@instructure/ui';

import ResultCell from './ResultCell';
import ResultRow from './ResultRow';
import ResultsList from './ResultsList';

import { getSchoolFromDeptId } from '../api';

const DepartmentResultsList = ({
  isFetching,
  onRowClick,
  results,
  selectedId,
  schoolLookup,
}) => (
  <ResultsList
    headerInfo={[
      { id: 'dept_name', text: 'Department' },
      { id: 'number_of_results', text: '# of Courses' },
    ]}
    isFetching={isFetching}
    hasMoreResults={isFetching}
    showResultsCount={true}
    size={results.length}
    isPaginated={false}
  >
    {Object.keys(schoolLookup).length &&
      results.map(item => (
        <ResultRow
          key={item.dept_id}
          /* Disable onClick() handler for selected row so that user can select
            text (if React has to repaint, it can reset current text selection)
          */
          onClick={item.dept_id !== selectedId ? onRowClick : null}
          rowId={item.dept_id}
          selected={item.dept_id === selectedId}
        >
          <ResultCell>
            <InlineList margin="none 0" size="small">
              <InlineList.Item>
                {/* dept_id contains the school code, which is then
                used to look up a friendly school name */}
                {schoolLookup[getSchoolFromDeptId(item.dept_id)]}
              </InlineList.Item>
            </InlineList>
            <Text
              transform="capitalize"
              wrap="break-word"
              weight={item.dept_id === selectedId ? 'bold' : 'normal'}
            >
              {item.dept_name}
            </Text>
          </ResultCell>
          <ResultCell>
            <Text weight={item.dept_id === selectedId ? 'bold' : 'normal'}>
              {item.n}
            </Text>
          </ResultCell>
        </ResultRow>
      ))}
  </ResultsList>
);

export default DepartmentResultsList;
