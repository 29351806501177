import { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  Flex,
  Heading,
  InlineList,
  Link as UiLink,
  Text,
  View,
} from '@instructure/ui';

import ResponsiveMatch from './ResponsiveMatch';

import { useMyHarvardCourseDetailFetch } from '../api';
import { useCourseDetailNavigation, useNavigateTo } from '../navigation';

import {
  CANVAS_URL,
  CONTACT_US_LINK_TEXT,
  HARVARD_LINK_URL,
  HELP_LINK_TEXT,
  RESOURCES_LINK_TEXT,
} from '../ui';

import {
  useCourseSearchNavigation,
  useDepartmentSearchNavigation,
} from '../navigation';

import MainSearchBox from './MainSearchBox';
import ExternalLink from './ExternalLink';

const Welcome = ({ myHarvardCourseId, setIsContactModalOpen }) => {
  const [currentSearchQueryInput, setcurrentSearchQueryInput] = useState('');

  const { results: courseDetail, error: courseDetailError } =
    useMyHarvardCourseDetailFetch(myHarvardCourseId);
  if (courseDetailError) throw courseDetailError; // throw error for ErrorBoundary

  const navigateToHelp = useNavigateTo('/help');
  const navigateToResources = useNavigateTo('/resources');
  const navigateToCourseDetails = useCourseDetailNavigation({
    query: courseDetail.course_code,
  });
  const navigateNewCourseSearch = useCourseSearchNavigation({
    query: currentSearchQueryInput.trim(),
  });
  const navigateNewDepartmentSearch = useDepartmentSearchNavigation({
    query: currentSearchQueryInput.trim(),
  });

  const openContactUsModal = () => setIsContactModalOpen(true);

  if (courseDetail?.doc_id) navigateToCourseDetails(courseDetail.doc_id);

  return myHarvardCourseId ? null : (
    <Flex id="welcome-container" direction="column" alignItems="center">
      <Flex.Item padding="small">
        <View display="block" width="50rem">
          <Heading level="h1">
            Welcome to the Harvard Syllabus Explorer!
          </Heading>
          <MainSearchBox
            searchQuery={currentSearchQueryInput}
            onSearchQueryChange={setcurrentSearchQueryInput}
            onSubmitSearchCourses={navigateNewCourseSearch}
            onSubmitSearchDepartments={navigateNewDepartmentSearch}
          />
        </View>
        <View display="block" width="50rem">
          <Text data-testid="course-search-welcome-msg">
            <p>
              Syllabus Explorer allows you to search more than 10,000 Harvard
              syllabi, to find classes, instructors, and course material
              relevant to your interests.
            </p>
            <p>
              Syllabus Explorer uses machine learning to analyze syllabi
              uploaded to the{' '}
              <ExternalLink href={CANVAS_URL}>
                Harvard Canvas system
              </ExternalLink>{' '}
              as text, links, or uploaded files (such as PDFs and Word docs).
              The database includes syllabi dating back to the 2015-16 academic
              year and is updated every semester. More recent courses are most
              likely to have searchable syllabi in the Syllabus Explorer
              database.
            </p>
            <p>
              Syllabus Explorer is a project developed in partnership by the
              Office of the Vice Provost for Advances and Learning and Harvard
              University IT.
            </p>
            <p>
              For more ways to extend your search, check out{' '}
              <ExternalLink href={HARVARD_LINK_URL}>Harvard Link</ExternalLink>,
              where you can keyword search across Harvard news, events, faculty,
              and organizations. Link aggregates information from hundreds of
              Harvard sources on an ongoing basis, allowing you to explore how
              Harvard experts and research are impacting the University and
              beyond.
            </p>
            <div id="welcome-help-links">
              <ResponsiveMatch
                small={
                  <>
                    <Button
                      color="secondary"
                      display="block"
                      margin="medium none none none"
                      onClick={navigateToHelp}
                    >
                      {HELP_LINK_TEXT}
                    </Button>
                    <Button
                      color="secondary"
                      display="block"
                      margin="medium none none none"
                      onClick={navigateToResources}
                    >
                      {RESOURCES_LINK_TEXT}
                    </Button>
                    <Button
                      color="secondary"
                      display="block"
                      margin="medium none none none"
                      onClick={setIsContactModalOpen}
                    >
                      {CONTACT_US_LINK_TEXT}
                    </Button>
                  </>
                }
                large={
                  <InlineList delimiter="pipe" margin="none 0">
                    <InlineList.Item>
                      <Link to={'/help'}>{HELP_LINK_TEXT}</Link>
                    </InlineList.Item>
                    <InlineList.Item>
                      <ExternalLink href={'/resources'}>
                        {RESOURCES_LINK_TEXT}
                      </ExternalLink>
                    </InlineList.Item>
                    <InlineList.Item>
                      <UiLink onClick={openContactUsModal}>
                        {CONTACT_US_LINK_TEXT}
                      </UiLink>
                    </InlineList.Item>
                  </InlineList>
                }
              />
            </div>
          </Text>
        </View>
      </Flex.Item>
    </Flex>
  );
};

export default Welcome;
