import ResultsList from './ResultsList';

import { IconButton, IconInfoLine, Text, Tooltip } from '@instructure/ui';

import ResultCell from './ResultCell';
import ResultRow from './ResultRow';
import RelevanceCell from './RelevanceCell';

const TermResultsList = ({
  instances,
  isFetching,
  isSimilarResults = false,
  onRowClick,
  selectedId,
}) => (
  <ResultsList
    tableCaption="Course similarity for different terms"
    headerInfo={[
      { id: 'term_name', text: 'Term', sort: 'descending' },
      { id: 'instructors', text: 'Instructor' },
      {
        id: isSimilarResults ? 'similarity' : 'relevance',
        text: (
          <>
            <span>{isSimilarResults ? 'Similarity' : 'Relevance'}</span>
            <Tooltip
              renderTip={`${
                isSimilarResults ? 'Similarity' : 'Relevance'
              } may vary due to course content changes over time`}
              placement="bottom"
              constrain="parent"
              on={['click', 'hover', 'focus']}
            >
              <IconButton
                renderIcon={IconInfoLine}
                size="small"
                withBackground={false}
                withBorder={false}
                screenReaderLabel={`Toggle info about term ${
                  isSimilarResults ? 'similarity' : 'relevance'
                }`}
              />
            </Tooltip>
          </>
        ),
      },
    ]}
    isFetching={isFetching}
    noResultsMessageText={'No term information available.'}
  >
    {instances.map(item => {
      // Note: pre-2021 API returns canvas_course_id while 2021+ API
      // returns instance_id; this supports both but the canvas_course_id
      // can be removed once the old API is retired
      const instanceId = item.canvas_course_id || item.instance_id;
      return (
        <ResultRow
          key={instanceId}
          onClick={onRowClick}
          rowId={instanceId}
          selected={instanceId === selectedId}
        >
          <ResultCell>
            {instanceId === selectedId ? (
              <strong> {item.term_name} </strong>
            ) : (
              item.term_name
            )}
          </ResultCell>
          <ResultCell>
            <Text weight={instanceId === selectedId ? 'bold' : 'normal'}>
              {item?.all_instructor_names?.includes(';')
                ? 'Multiple'
                : item.all_instructor_names}
            </Text>
          </ResultCell>
          <RelevanceCell value={item.score} />
        </ResultRow>
      );
    })}
  </ResultsList>
);

export default TermResultsList;
