import { Heading, Text } from '@instructure/ui';
import ResultsHeadingSearchQuery from './ResultsHeadingSearchQuery';

const ResultsHeading = ({ heading, detail, detailScope }) => (
  <div id="results-heading">
    <Heading level="h4">{heading}</Heading>
    <Text weight="bold" size="large">
      <ResultsHeadingSearchQuery>{detail}</ResultsHeadingSearchQuery>
    </Text>
    {detailScope && (
      <>
        <Text size="large"> in </Text>
        <Text weight="bold" size="large">
          {detailScope}
        </Text>
      </>
    )}
  </div>
);

export default ResultsHeading;
