import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { mergeDeep } from '@instructure/ui-utils';
import { Flex } from '@instructure/ui';

import CollapsedQueryDetails from './CollapsedQueryDetails';
import CourseDetail from './CourseDetail';
import CourseResultsList from './CourseResultsList';
import CustomTruncateText from './CustomTruncateText';
import LeftSearchBar from './LeftSearchBar';
import ResultsHeading from './ResultsHeading';
import ReturnFromResults from './ReturnFromResults';
import Search from './Search';

import { useCourseDetailFetch, useCoursesFetch } from '../api';
import {
  useCourseDetailNavigation,
  useCourseSearchNavigation,
  useDepartmentDetailNavigation,
  useSimilarCourseSearchNavigation,
} from '../navigation';
import {
  getInstanceScoresLookupForDocId,
  UI_PAGE_RESULT_DETAILS,
  UI_PAGE_SEARCH_RESULTS,
} from '../ui';

const SearchCourses = ({
  courseDetailDocId,
  departments,
  departmentDocId, // available if coming from department search via View Related Search
  filterLabels,
  filters,
  schoolLookup,
  searchQuery,
}) => {
  /* ui: on small screens we use the inst-ui "Page" component to foreground
  particular parts of the UI; `uiPageIndex` tracks which page should be visible
  at a given time */
  const [uiPageIndex, setUiPageIndex] = useState(
    courseDetailDocId ? UI_PAGE_RESULT_DETAILS : UI_PAGE_SEARCH_RESULTS,
  );

  /* ui: on small screens we use the inst-ui "Tray" component to hide away
  search and filter details unless needed; `isSearchTrayOpen` tracks state */
  const [isSearchTrayOpen, setIsSearchTrayOpen] = useState(false);

  /* api: `page` tracks which page of results we've fetched up to; calling
    `fetchMoreResults()` will trigger loading the next page in sequence */
  const [resultsPage, setResultsPage] = useState(0);
  const fetchMoreResults = () => setResultsPage(prevPage => prevPage + 1);

  // custom fetch hooks
  const {
    results: courseResults,
    error: coursesError,
    hasMore: hasMoreResults,
    isFetching,
  } = useCoursesFetch(searchQuery, filters, resultsPage);
  if (coursesError) throw coursesError; // throw error for ErrorBoundary

  const {
    results: courseDetail,
    isFetching: isFetchingCourseDetail,
    error: courseDetailError,
  } = useCourseDetailFetch(courseDetailDocId !== '' && courseDetailDocId);
  if (courseDetailError) throw courseDetailError; // throw error for ErrorBoundary

  // supplement the course detail information with the relevance scores for each term/instance
  const scoreLookup = getInstanceScoresLookupForDocId(
    courseResults,
    courseDetailDocId,
  );
  if (courseDetail.instances) {
    // Note: pre-2021 API returns canvas_course_id while 2021+ API
    // returns instance_id; this supports both but the canvas_course_id
    // can be removed once the old API is retired
    courseDetail.instances = courseDetail.instances.map(i => ({
      ...i,
      score: scoreLookup[i.canvas_course_id || i.instance_id],
    }));
  }

  // custom navigation hooks
  const navigateCourseDetail = useCourseDetailNavigation({
    query: searchQuery.trim(),
    filters: { d: departmentDocId, ...filters },
  });
  /* calling with `{}` ensures that the search query and filters will be
    sent in by the downstream UI component instead of based on the state
    tracked in this component */
  const navigateSearch = useCourseSearchNavigation({});
  const navigateSimilarCourses = useSimilarCourseSearchNavigation({
    courseId: courseDetailDocId,
    courseCode: courseDetail.course_code,
    query: searchQuery.trim(),
    filters: {
      /* kick off a new, unfiltered similar courses search, but retain
        current course search filter values so the user can return to this
        search results set */
      courseSearchDeptFilter: filters.deptFilter,
      courseSearchSchoolFilter: filters.schoolFilter,
      courseSearchWhenFilter: filters.whenFilter,
    },
  });
  const navigateDepartmentDetailSearch = useDepartmentDetailNavigation({
    query: searchQuery.trim(),
    /* return to department search view, and restore original department
      search filter values to return to the expected original results set */
    filters: {
      deptFilter: filters.deptSearchDeptFilter,
      schoolFilter: filters.deptSearchSchoolFilter,
    },
  });

  const showCourseDetail = selectedCourseId => {
    setUiPageIndex(UI_PAGE_RESULT_DETAILS);
    navigateCourseDetail(selectedCourseId);
  };

  const performSearch = (newQuery, newFilters) => {
    setUiPageIndex(UI_PAGE_SEARCH_RESULTS);
    setIsSearchTrayOpen(false);
    setResultsPage(0);
    navigateSearch(newQuery.trim(), {
      d: departmentDocId,
      ...mergeDeep(filters, newFilters),
    });
  };

  if (!searchQuery) return <Redirect to="/" />;
  return (
    <Search
      queryTrayOpen={isSearchTrayOpen}
      onQueryTrayClose={() => setIsSearchTrayOpen(false)}
      activePageIndex={uiPageIndex}
      setActivePageIndex={setUiPageIndex}
      query={
        <>
          {departmentDocId && (
            <p>
              Filter course matches for: <br />
              <strong>
                <CustomTruncateText>{searchQuery}</CustomTruncateText>
              </strong>
              {departments[departmentDocId]?.name && (
                <>
                  {' '}
                  in <strong>{departments[departmentDocId].name}</strong>
                </>
              )}
            </p>
          )}
          <LeftSearchBar
            // restrict searching if coming from department results via View Similar
            filterOnly={departmentDocId}
            onSubmit={performSearch}
            filterLabels={filterLabels}
            searchQuery={searchQuery}
            searchFilters={filters}
            // only allow filtering course results if coming from department results via View Similar
            submitLabel={departmentDocId ? 'Filter courses' : 'Search courses'}
          />
        </>
      }
      results={
        <Flex direction="column" as="div" height="100%">
          {departmentDocId && (
            <Flex.Item as="div" id="return-from-results">
              <ReturnFromResults
                returnTarget={'school search results'}
                onClick={() => navigateDepartmentDetailSearch(departmentDocId)}
              />
            </Flex.Item>
          )}
          <Flex.Item as="div" overflowY="hidden">
            <ResultsHeading
              heading={
                departmentDocId
                  ? 'Top Course Matches for:'
                  : 'Top Course Results for:'
              }
              detail={searchQuery}
              detailScope={
                departmentDocId ? departments[departmentDocId]?.name : null
              }
            />
            <CollapsedQueryDetails
              onEdit={() => setIsSearchTrayOpen(true)}
              filterLabels={filterLabels}
              filters={filters}
            />
          </Flex.Item>
          <Flex.Item as="div" shouldGrow shouldShrink>
            <CourseResultsList
              results={courseResults}
              isFetching={isFetching}
              fetchMoreRows={fetchMoreResults}
              hasMoreResults={hasMoreResults}
              onCourseRowClick={showCourseDetail}
              selectedId={courseDetailDocId}
              showSimilar={true}
              schoolLookup={schoolLookup}
            />
          </Flex.Item>
        </Flex>
      }
      details={
        <CourseDetail
          onClose={() => performSearch(searchQuery, filters)}
          courseDetail={courseDetailDocId !== '' ? courseDetail : null}
          courseDetailLoading={isFetchingCourseDetail}
          onSubmitViewSimilarCourses={navigateSimilarCourses}
          // allow viewing similar courses only if not coming from department results via View Similar
          showSimilar={!departmentDocId}
        />
      }
      /* signal to the <Search> component that the details panel is active
        (so it can be highlighted); Search cannot currently inspect the `details`
        prop to infer this state */
      detailActive={courseDetail && Object.entries(courseDetail).length > 0}
    />
  );
};

export default SearchCourses;
