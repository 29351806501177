import { useState } from 'react';

import { Tabs } from '@instructure/ui';

import MainSearchBoxForm from './MainSearchBoxForm';

const MainSearchBox = ({
  onSearchQueryChange,
  onSubmitSearchCourses,
  onSubmitSearchDepartments,
  searchQuery,
}) => {
  const [selectedIndex, setselectedIndex] = useState(0);

  const handleSearchQueryChange = (e, v) => {
    onSearchQueryChange(v);
  };

  const handleSubmitSearchCourses = e => {
    onSubmitSearchCourses(e);
    e.preventDefault();
  };

  const handleSubmitSearchDepartments = e => {
    onSubmitSearchDepartments(e);
    e.preventDefault();
  };

  const handleTabChange = (event, { index, id }) => {
    setselectedIndex(index);
  };

  return (
    <div id="main-search-box">
      <Tabs
        margin="large auto"
        variant="secondary"
        padding="medium"
        onRequestTabChange={handleTabChange}
      >
        <Tabs.Panel
          id="search-courses"
          padding="large"
          renderTitle="Search Courses"
          isSelected={selectedIndex === 0}
        >
          <MainSearchBoxForm
            buttonLabel="Search Courses"
            onSearchQueryChange={handleSearchQueryChange}
            onSubmit={handleSubmitSearchCourses}
            searchInstructions={
              <>
                Find specific <b>courses and syllabi</b> using keywords and
                filters:
              </>
            }
            searchQuery={searchQuery}
            searchType="course"
          />
        </Tabs.Panel>
        <Tabs.Panel
          id="search-schools"
          isSelected={selectedIndex === 1}
          padding="large"
          renderTitle="Search Schools"
        >
          <MainSearchBoxForm
            buttonLabel="Search Schools"
            onSearchQueryChange={handleSearchQueryChange}
            onSubmit={handleSubmitSearchDepartments}
            searchInstructions={
              <>
                Find <b>departments and schools</b> that offer courses related
                to your criteria:
              </>
            }
            searchQuery={searchQuery}
            searchType="school"
          />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default MainSearchBox;
