import { Responsive } from '@instructure/ui';

const SCREEN_WIDTH_BREAKPOINT = '60rem';

const ResponsiveMatch = ({ small = null, large = null }) => (
  /* Wrap this component around anything that should appear differently on small
    screen sizes from large screen sizes; pass content to `small` that should be
    rendered on small screen sizes, and to `large` for wider screens */
  <Responsive
    match="media"
    query={{
      small: { maxWidth: SCREEN_WIDTH_BREAKPOINT },
      large: { minWidth: SCREEN_WIDTH_BREAKPOINT },
    }}
  >
    {(props, matches) => {
      return matches.includes('small') ? small : large;
    }}
  </Responsive>
);
export default ResponsiveMatch;
