import { CondensedButton, IconSearchSolid, Text } from '@instructure/ui';

import ResponsiveMatch from './ResponsiveMatch';

const CollapsedQueryDetails = ({ filterLabels, filters, onEdit }) => {
  let filterListItems = [];
  for (const f in filters) {
    if (filters[f] && filterLabels?.[f]?.caption)
      filterListItems.push(
        <li key={f}>
          {filterLabels[f].caption}:{' '}
          {filterLabels[f]?.display?.[filters[f]] || filters[f]}
        </li>,
      );
  }
  return (
    <ResponsiveMatch
      small={
        <div id="collapsed-query-details-container">
          <div id="collapsed-query-details-filter-list">
            <Text as="div" size="small">
              <strong>Filters:</strong>{' '}
              <ul className="filter-list">
                {filterListItems.length > 0 ? filterListItems : <li>None</li>}
              </ul>
            </Text>
          </div>
          <div id="collapsed-query-details-edit">
            <CondensedButton
              id="collapsed-query-details-button"
              size="small"
              onClick={onEdit}
            >
              <IconSearchSolid /> Edit your search
            </CondensedButton>
          </div>
        </div>
      }
    />
  );
};
export default CollapsedQueryDetails;
