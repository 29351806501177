import { Fragment, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { mergeDeep } from '@instructure/ui-utils';
import { Flex } from '@instructure/ui';

import CollapsedQueryDetails from './CollapsedQueryDetails';
import CourseDetail from './CourseDetail';
import CourseResultsList from './CourseResultsList';
import LeftSearchBar from './LeftSearchBar';
import ResultsHeading from './ResultsHeading';
import ReturnFromResults from './ReturnFromResults';
import Search from './Search';

import { useCourseDetailFetch, useSimilarCoursesFetch } from '../api';
import {
  useCourseDetailNavigation,
  useSimilarCourseDetailNavigation,
  useSimilarCourseSearchNavigation,
} from '../navigation';
import {
  getInstanceScoresLookupForDocId,
  UI_PAGE_RESULT_DETAILS,
  UI_PAGE_SEARCH_RESULTS,
} from '../ui';

const SearchSimilarCourses = ({
  courseDetailDocId, // currently highlighted course ID (from result row)
  courseCode, // course code of the original course (from original search)
  searchQuery, // query string of original search
  filterLabels,
  filters,
}) => {
  const { courseId } = useParams(); // course ID of original course (from original search)

  /* ui: on small screens we use the inst-ui "Page" component to foreground
  particular parts of the UI; `uiPageIndex` tracks which page should be visible
  at a given time */
  const [uiPageIndex, setUiPageIndex] = useState(
    courseDetailDocId ? UI_PAGE_RESULT_DETAILS : UI_PAGE_SEARCH_RESULTS,
  );

  /* ui: on small screens we use the inst-ui "Tray" component to hide away
  search and filter details unless needed; `isSearchTrayOpen` tracks state */
  const [isSearchTrayOpen, setIsSearchTrayOpen] = useState(false);

  /* api: `page` tracks which page of results we've fetched up to; calling
    `fetchMoreResults()` will trigger loading the next page in sequence */
  const [resultsPage, setResultsPage] = useState(0);
  const fetchMoreResults = () => setResultsPage(prevPage => prevPage + 1);

  // custom fetch hooks
  const {
    results: courseDetail,
    isFetching: isFetchingCourseDetail,
    error: courseDetailError,
  } = useCourseDetailFetch(courseDetailDocId);
  if (courseDetailError) throw courseDetailError; // throw error for ErrorBoundary

  const {
    results: searchResults,
    error: searchError,
    hasMore: hasMoreResults,
    isFetching,
  } = useSimilarCoursesFetch(courseId, filters, resultsPage);
  if (searchError) throw searchError; // throw error for ErrorBoundary

  // supplement the course detail information with the similarity scores for each term/instance
  const scoreLookup = getInstanceScoresLookupForDocId(
    searchResults,
    courseDetailDocId,
  );
  if (courseDetail.instances) {
    // Note: pre-2021 API returns canvas_course_id while 2021+ API
    // returns instance_id; this supports both but the canvas_course_id
    // can be removed once the old API is retired
    courseDetail.instances = courseDetail.instances.map(i => ({
      ...i,
      score: scoreLookup[i.canvas_course_id || i.instance_id],
    }));
  }

  const navigateOriginalCourseDetail = useCourseDetailNavigation({
    query: searchQuery.trim(),
    filters: {
      /* return to course search view, and restore original course
      search filter values to return to the expected original results set */
      deptFilter: filters.courseSearchDeptFilter,
      schoolFilter: filters.courseSearchSchoolFilter,
      whenFilter: filters.courseSearchWhenFilter,
    },
  });

  // custom navigation hooks
  const navigateDetail = useSimilarCourseDetailNavigation({
    courseId: courseId,
    courseCode: courseCode,
    query: searchQuery.trim(),
    filters,
  });
  /* calling with without `filters` argument ensures that the filters will be
    sent in by the downstream UI component instead of based on the state
    tracked in this component */
  const navigateSearch = useSimilarCourseSearchNavigation({
    courseId: courseId,
    courseCode: courseCode,
    query: searchQuery.trim(),
  });

  const showCourseDetail = selectedCourseId => {
    setUiPageIndex(UI_PAGE_RESULT_DETAILS);
    navigateDetail(selectedCourseId);
  };

  const performSearch = (newQuery, newFilters) => {
    setUiPageIndex(UI_PAGE_SEARCH_RESULTS);
    setIsSearchTrayOpen(false);
    setResultsPage(0);
    // for similar course search, `newQuery` should never have changed, but it is
    // part of the performSearch parameter API; navigate using searchQuery instead
    navigateSearch(searchQuery.trim(), mergeDeep(filters, newFilters));
  };

  if (!searchQuery) return <Redirect to="/" />;
  return (
    <Search
      queryTrayOpen={isSearchTrayOpen}
      onQueryTrayClose={() => setIsSearchTrayOpen(false)}
      activePageIndex={uiPageIndex}
      setActivePageIndex={setUiPageIndex}
      query={
        <Fragment>
          <p>
            Filter courses similar to: <br />
            <strong>{courseCode}</strong>
          </p>
          <LeftSearchBar
            filterLabels={filterLabels}
            filterOnly={true}
            formDescription="Course filter form"
            onSubmit={performSearch}
            searchFilters={filters}
            searchQuery={searchQuery} // used in "or Search Schools" link
            submitLabel="Filter courses"
          />
        </Fragment>
      }
      results={
        <Flex direction="column" as="div" height="100%">
          <Flex.Item as="div" id="return-from-results">
            <ReturnFromResults
              returnTarget={courseCode}
              onClick={() => navigateOriginalCourseDetail(courseId)}
            />
          </Flex.Item>
          <Flex.Item as="div" overflowY="hidden">
            <ResultsHeading
              heading={'Top Courses Most Similar to:'}
              detail={courseCode}
            />
            <CollapsedQueryDetails
              onEdit={() => setIsSearchTrayOpen(true)}
              filterLabels={filterLabels}
              filters={filters}
            />
          </Flex.Item>
          <Flex.Item as="div" shouldGrow shouldShrink>
            <CourseResultsList
              results={searchResults}
              isFetching={isFetching}
              fetchMoreRows={fetchMoreResults}
              hasMoreResults={hasMoreResults}
              onCourseRowClick={showCourseDetail}
              selectedId={courseDetailDocId}
              showSimilar={false}
            />
          </Flex.Item>
        </Flex>
      }
      details={
        <CourseDetail
          onClose={() => performSearch(searchQuery, filters)}
          courseDetail={courseDetailDocId !== '' ? courseDetail : null}
          courseDetailLoading={isFetchingCourseDetail}
          showSimilar={false}
        />
      }
      /* signal to the <Search> component that the details panel is active
        (so it can be highlighted); Search cannot currently inspect the `details`
        prop to infer this state */
      detailActive={courseDetail && Object.entries(courseDetail).length > 0}
    />
  );
};

export default SearchSimilarCourses;
