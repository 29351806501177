import Cookies from 'js-cookie';
import jwtDecode, { InvalidTokenError } from 'jwt-decode';
import * as Sentry from '@sentry/react';

// name of cookie that auth server uses to provide JWT token to client app
const JWT_COOKIE = 'HU_SE_JWT';
const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
// retain JWT information until it expires or the app is reloaded
let currentAuth = {};

export const clearAuth = () => (currentAuth = {});

export const getJWT = () => {
  if (!currentAuth.token) {
    currentAuth.token = Cookies.get(JWT_COOKIE);
  }
  return currentAuth.token;
};

export const getJWTClaims = () => {
  if (!currentAuth.claims) {
    const token = getJWT();
    if (!token) return {};
    try {
      currentAuth.claims = jwtDecode(token);
    } catch (e) {
      console.log('jwt error', e);
      clearAuth();
      if (e instanceof InvalidTokenError) return {};
      throw e;
    }
  }
  return currentAuth.claims;
};

export const isAuthenticated = () => {
  // running local R API currently doesn't support https connections,
  // so we have to assume we're authenticated as attempting SSO login
  // without SSL will not work
  if (process.env.REACT_APP_LOCAL_API_TOKEN) return true;
  const data = getJWTClaims();
  // todo: check others, e.g sub, iss, ...
  const now = Math.floor(Date.now() / 1000);
  const authenticated = Boolean(data.exp && data.exp > now);
  if (authenticated) {
    Sentry.setUser({ username: data.sub });
  } else {
    clearAuth();
    Sentry.configureScope(scope => scope.setUser(null));
  }
  return authenticated;
};

export const login = () => {
  // https://github.com/ReactTraining/react-router/issues/3109#issuecomment-187255194
  // React Router's location object is not equivalent to window.location,
  // and cannot be used to generate fully qualified URLs as easily, so for now, using
  // window.location (see https://reacttraining.com/react-router/web/api/location)
  const location = window.location;

  // indicate that upon returning from SSO the page load should not attempt to forward
  // to SSO again if there was any authentication issue by setting loginRedirect=true
  let returnUrl = new URL(location.toString());
  let params = new URLSearchParams(location.search);
  params.set('loginRedirect', 'true');
  returnUrl.search = params.toString();

  // generate SSO login URL, and set the return URL to the current URL with the additional
  // loginRedirect=true included in the search params
  let loginUrl = new URL(LOGIN_URL);
  loginUrl.search = new URLSearchParams({
    next: returnUrl.toString(),
  }).toString();

  // redirect to SSO login URL
  window.location.assign(loginUrl);
};
