import { Component } from 'react';
import { Table, View } from '@instructure/ui';
import { v4 as uuidv4 } from 'uuid';

const defaultDisplay = 'inline-block';

export default class ResultCell extends Component {
  /* need to identify this component as "Cell" so that it can be a valid child of a
      Table.Row: https://github.com/instructure/instructure-ui/issues/673#issuecomment-895250758
  */
  static displayName = 'Cell';

  render = () => (
    <Table.Cell
      key={this.props.key || uuidv4() /* default to uuid if no `key` */}
      /* Note that @instructure/ui-table does not support adding
        class names to <td> or <td> elements, so we use data-*
        fields instead; here, we label certain fields so the
        underlying <td> can be styled with CSS
      */
      data-field={this.props.field}
    >
      <View
        display={this.props.display || defaultDisplay}
        margin="none"
        padding="x-small"
      >
        {this.props.children}
      </View>
    </Table.Cell>
  );
}
