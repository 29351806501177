import { Button } from '@instructure/ui';

const LeftSearchBarButton = ({ label, searchQuery }) => (
  <Button
    display="block"
    size="small"
    color="primary"
    margin="medium none none none"
    align="left"
    type="submit"
    id="submit-search-btn"
    interaction={
      searchQuery && searchQuery.trim() !== '' ? 'enabled' : 'disabled'
    }
  >
    {label}
  </Button>
);

export default LeftSearchBarButton;
