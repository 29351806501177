import {
  Button,
  CloseButton,
  FormFieldGroup,
  Heading,
  ScreenReaderContent,
  Text,
  View,
} from '@instructure/ui';

import DetailViewBackToResultsButton from './DetailViewBackToResultsButton';
import LoadingSpinner from './LoadingSpinner';
import MapContainer from './Gmap';
import ResponsiveMatch from './ResponsiveMatch';

import { getSchoolFromDeptId } from '../api';

const DepartmentDetail = ({
  detail,
  schoolLookup,
  isLoading,
  onClose,
  onSubmitViewRelatedCourses,
}) => {
  // todo: validate `detail` is a valid object, perhaps using PropTypes?

  if (isLoading || (detail && Object.entries(detail).length === 0)) {
    return LoadingSpinner;
  } else if (!detail) {
    return (
      <View textAlign="center" display="block" padding="medium">
        <Text as="p" fontStyle="italic" weight="light">
          Choose a department to view details and related courses.
        </Text>
      </View>
    );
  }

  const handleSubmitViewRelatedCourses = e => {
    onSubmitViewRelatedCourses(e);
    e.preventDefault();
  };

  return (
    <View
      as="div"
      className="selectedDepartment"
      display="block"
      height="100%"
      overflowY="auto"
      padding="medium"
      position="relative"
      textAlign="start"
    >
      <ResponsiveMatch
        small={
          <DetailViewBackToResultsButton onClick={onClose}>
            &lt; Go back to school search results
          </DetailViewBackToResultsButton>
        }
        large={
          <CloseButton
            size="small"
            color="primary"
            placement="end"
            offset="small"
            onClick={onClose}
            screenReaderLabel="Close"
            interaction="enabled"
          ></CloseButton>
        }
      />
      <div id="department-detail-header">
        <Heading level="h4">Department Details:</Heading>
      </div>
      <p>
        <Text size="small" color="secondary">
          {schoolLookup[getSchoolFromDeptId(detail.id)]}
        </Text>
        <br />
        <Text weight="bold">{detail.name}</Text>
      </p>
      <div id="department-detail-related-courses">
        <form onSubmit={handleSubmitViewRelatedCourses}>
          <FormFieldGroup
            layout="stacked"
            rowSpacing="medium"
            description={
              <ScreenReaderContent>
                View related courses form
              </ScreenReaderContent>
            }
            label="label"
          >
            <Button
              align="left"
              color="secondary"
              id="department-details-view-related-courses-button"
              margin="medium none none none"
              size="small"
              type="submit"
            >
              View Related Courses
            </Button>
          </FormFieldGroup>
        </form>
      </div>
      <Heading level="h5">
        <Text weight="bold">Website:</Text>
      </Heading>
      <Text as="p" size="small">
        {/* Note: `typeof` handles issue where some API responses have malformed values for this field */}
        {typeof detail.url === 'string' && detail.url ? (
          <a rel="noopener noreferrer" target="_blank" href={` ${detail.url}`}>
            {' '}
            {detail.url}
          </a>
        ) : (
          '(none)'
        )}
      </Text>
      <Heading level="h5">
        <Text weight="bold">Address:</Text>
      </Heading>
      <Text as="p">
        {/* Note: `typeof` handles issue where some API responses have malformed values for this field */}
        {typeof detail.address === 'string' ? detail.address : '(none)'}
      </Text>
      <View display="block" margin="small">
        <MapContainer address={`${detail.address}`} />
      </View>
    </View>
  );
};

export default DepartmentDetail;
