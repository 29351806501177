import { Router } from 'react-router-dom';

import './App.css';

import ErrorBoundary from './ErrorBoundary';
import { initializeGA, withTracker } from './analyticsTracker';
import { isAuthenticated } from './auth';
import Routes from './Routes';
import { useSchoolsFetch } from './api';

initializeGA();

const InitApp = () => {
  const isAuthned = isAuthenticated();
  const { results: schoolList, error: schoolsError } =
    useSchoolsFetch(isAuthned);
  if (schoolsError) throw schoolsError; // throw error for ErrorBoundary
  return <Routes schools={schoolList} />;
};

export const AppWithErrorBoundary = () => (
  /* Include ErrorBoundary here so that if any child components or regions do not
    have their own ErrorBoundary defined there is a basic one to show a friendly
    error message. */
  <ErrorBoundary>
    <InitApp />
  </ErrorBoundary>
);

const TrackedAppWithErrorBoundary = withTracker(AppWithErrorBoundary);

const App = ({ history }) => (
  <Router history={history}>
    <TrackedAppWithErrorBoundary />
  </Router>
);

export default App;
