import { useEffect, useState } from 'react';
import { Alert, Select } from '@instructure/ui';

function SingleSelectExample({
  label,
  onFilterChange,
  options,
  placeholder,
  selectedOptionId,
}) {
  const [inputValue, setInputValue] = useState(null);

  const [isShowingOptions, setIsShowingOptions] = useState(false);
  const [highlightedOptionId, setHighlightedOptionId] = useState(null);
  const [currentSelectedOptionId, setCurrentSelectedOptionId] =
    useState(selectedOptionId);
  const [announcement, setAnnouncement] = useState(null);

  /* ensure changes to values coming from route changes (e.g. if user navigates
    forward or backward in browser history) are reflected by select input and
    that old filter values are not being tracked by the parent components/state
  */
  useEffect(() => {
    setCurrentSelectedOptionId(selectedOptionId);
    onFilterChange(selectedOptionId);
  }, [onFilterChange, selectedOptionId]);

  /* when user selects an option, update the current input value as well
    with the associated option label
  */
  useEffect(() => {
    const getOptionById = queryId => {
      return options.find(({ id }) => id === queryId);
    };
    return setInputValue(getOptionById(currentSelectedOptionId)?.label);
  }, [currentSelectedOptionId, options]);

  const handleShowOptions = event => {
    setIsShowingOptions(true);
  };

  const handleHideOptions = event => {
    setIsShowingOptions(false);
    setHighlightedOptionId(null);
    setAnnouncement('List collapsed.');
  };

  const handleBlur = event => {
    setHighlightedOptionId(null);
  };

  const handleHighlightOption = (event, { id }) => {
    event.persist();
    const optionsAvailable = `${options.length} options available.`;
    const nowOpen = !isShowingOptions
      ? `List expanded. ${optionsAvailable}`
      : '';
    const option = options.find(({ OptionId }) => OptionId === id)?.label;
    setHighlightedOptionId(id);
    setInputValue(event.type === 'keydown' ? option : inputValue);
    setAnnouncement(`${option} ${nowOpen}`);
  };

  const handleSelectOption = (event, { id }) => {
    setCurrentSelectedOptionId(id);
    onFilterChange(id);
    setIsShowingOptions(false);
    setAnnouncement(`"${id}" selected. List collapsed.`);
  };

  return (
    <div className="select-input-container">
      <Select
        renderLabel={label}
        assistiveText="Use arrow keys to navigate options."
        inputValue={inputValue || ''}
        isShowingOptions={isShowingOptions}
        onBlur={handleBlur}
        onRequestShowOptions={handleShowOptions}
        onRequestHideOptions={handleHideOptions}
        onRequestHighlightOption={handleHighlightOption}
        onRequestSelectOption={handleSelectOption}
        size="small"
        display="block"
        margin="medium none none none"
        placeholder={placeholder}
      >
        {options.map(option => {
          return (
            <Select.Option
              id={option.id}
              key={option.id}
              isHighlighted={option.id === highlightedOptionId}
              isSelected={option.id === currentSelectedOptionId}
            >
              {option.label}
            </Select.Option>
          );
        })}
      </Select>
      <Alert
        liveRegion={() => document.getElementById('flash-messages')}
        liveRegionPoliteness="assertive"
        screenReaderOnly
      >
        {announcement}
      </Alert>
    </div>
  );
}

export default SingleSelectExample;
