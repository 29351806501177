import CourseSchoolDetailLine from './CourseSchoolDetailLine';
import CourseTitleLine from './CourseTitleLine';
import RelevanceCell from './RelevanceCell';
import ResultCell from './ResultCell';
import ResultRow from './ResultRow';
import ResultsList from './ResultsList';

const CourseResultsList = ({
  onCourseRowClick,
  isFetching,
  fetchMoreRows,
  hasMoreResults,
  results,
  selectedId,
  showSimilar,
}) => (
  <ResultsList
    headerInfo={[
      { id: 'course_title', text: 'Course Title' },
      {
        id: 'score',
        text: showSimilar ? 'Relevance' : 'Similarity',
        sort: 'descending',
        width: '8rem',
      },
    ]}
    isFetching={isFetching}
    fetchMoreRows={fetchMoreRows}
    hasMoreResults={hasMoreResults}
    showResultsCount={true}
    size={results.length}
  >
    {results.map(item => (
      <ResultRow
        key={item.doc_id}
        /* Disable onClick() handler for selected row so that user can select
          text (if React has to repaint, it can reset current text selection)
        */
        onClick={item.doc_id !== selectedId ? onCourseRowClick : null}
        rowId={item.doc_id}
        selected={item.doc_id === selectedId}
      >
        <ResultCell>
          <CourseTitleLine
            bold={item.doc_id === selectedId}
            courseCode={item.course_code}
            courseTitle={item.course_title}
          />
          <CourseSchoolDetailLine
            schoolName={item.school_name}
            deptName={item.dept_name}
            programName={item.program_name}
          />
        </ResultCell>
        <RelevanceCell value={item.score} />
      </ResultRow>
    ))}
  </ResultsList>
);

export default CourseResultsList;
