import { Text } from '@instructure/ui';

export const DEFAULT_CHAR_LIMIT = 50;

const CustomTruncateText = ({
  charLimit = DEFAULT_CHAR_LIMIT,
  children: text,
}) => {
  const renderText = content => (
    <Text data-testid="custom-truncate-text-content" wrap="break-word">
      {content}
    </Text>
  );
  return text.length <= charLimit
    ? renderText(text)
    : renderText(`${text.slice(0, charLimit)}...`);
};

export default CustomTruncateText;
