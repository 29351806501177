import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useHistory } from 'react-router-dom';
import { mergeDeep } from '@instructure/ui-utils';

const GA_TRACKING_DEBUG =
  process.env.REACT_APP_GOOGLE_ANALYTICS_DEBUG === 'true';
/* GA_MEASUREMENT_ID_REGEX should match the format for a Google Analytics 4
  measurement ID (not a web + app measurement ID)
  cf https://support.google.com/analytics/answer/12270356?hl=en */
const GA_MEASUREMENT_ID_REGEX = RegExp('G-[A-Za-z0-9]*');
const GA_MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;
const GA_DEFAULT_OPTIONS = {
  debug: GA_TRACKING_DEBUG,
  gaOptions: {
    storeGac: false,
  },
};
const GA_TRACKING_ENABLED = GA_MEASUREMENT_ID_REGEX.test(GA_MEASUREMENT_ID);

export const initializeGA = (options = {}) => {
  if (GA_TRACKING_ENABLED) {
    ReactGA.initialize(
      GA_MEASUREMENT_ID,
      mergeDeep(GA_DEFAULT_OPTIONS, options),
    );
    ReactGA.set({ allowAdFeatures: false });
    ReactGA.set({ anonymizeIp: true });
  }
};

export const logPageView = history => {
  if (GA_TRACKING_ENABLED) {
    /* listener is notified only for actions after initial page load, so
    log initial page load before registering listener; note that due to
    the fact that first load of the app requires redirecting to SSO for
    private routes, this means the first page load is recorded twice (once
    for initial load, and once when the app is reloaded after returning
    from SSO with auth cookie) */
    ReactGA.send({
      hitType: 'pageview',
      page: history.location.pathname + history.location.search,
      title: history,
    });
    history.listen(location => {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
        title: location,
      });
    });
  }
};

export const logDownloadEvent = labelName => {
  if (GA_TRACKING_ENABLED) {
    ReactGA.event({
      category: 'Downloads',
      action: 'Syllabus Download',
      label: labelName,
    });
  }
};

export const withTracker = WrappedComponent => {
  return props => {
    const history = useHistory();

    useEffect(() => {
      logPageView(history);
    }, [history]);

    return <WrappedComponent {...props} />;
  };
};
