import { Flex } from '@instructure/ui';

import MobileResults from './MobileResults';
import QueryTray from './QueryTray';
import ResponsiveMatch from './ResponsiveMatch';

const Search = ({
  query,
  results,
  details,
  detailActive,
  activePageIndex,
  setActivePageIndex,
  queryTrayOpen,
  onQueryTrayClose,
  resultsPageFocusElement,
}) => (
  <ResponsiveMatch
    small={
      <>
        <QueryTray isOpen={queryTrayOpen} onClose={onQueryTrayClose}>
          {query}
        </QueryTray>
        <MobileResults
          results={results}
          details={details}
          activePageIndex={activePageIndex}
          setActivePageIndex={setActivePageIndex}
          resultsPageFocusElement={resultsPageFocusElement}
        />
      </>
    }
    large={
      <Flex
        justifyItems="center"
        alignItems="stretch"
        height="100%"
        id="search-results"
        as="div"
      >
        <Flex.Item id="search-bar" width="13rem" padding="small" as="div">
          {query}
        </Flex.Item>
        <Flex.Item
          as="div"
          id="results"
          shouldGrow
          size="20%"
          margin="none none none small"
        >
          {results}
        </Flex.Item>
        {/* Note that @instructure/ui-flex does not support adding
                class names to <span> elements generated by <Flex.Item>,
                so we use data-* fields instead; here, we flag the detail
                panel so it can be styled using CSS
              */}
        <Flex.Item
          width="40%"
          id="detail-view"
          data-active={detailActive}
          as="div"
        >
          {details}
        </Flex.Item>
      </Flex>
    }
  />
);

export default Search;
