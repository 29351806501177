import { TextInput } from '@instructure/ui';

const LeftSearchBarQuery = ({ onChange, searchQuery }) => {
  const handleChange = (e, v) => {
    e.preventDefault();
    onChange(v);
  };

  return (
    <TextInput
      renderLabel="Edit your search:"
      type="search"
      display="block"
      margin="x-small"
      size="small"
      value={searchQuery}
      onChange={handleChange}
    />
  );
};

export default LeftSearchBarQuery;
