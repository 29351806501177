import { InlineList } from '@instructure/ui';

const CourseSchoolDetailLine = ({ schoolName, deptName, programName }) => (
  <InlineList delimiter="pipe" margin="none 0" size="small">
    <InlineList.Item>{schoolName}</InlineList.Item>
    {deptName !== schoolName && <InlineList.Item>{deptName}</InlineList.Item>}
    {programName !== deptName && (
      <InlineList.Item>{programName}</InlineList.Item>
    )}
  </InlineList>
);

export default CourseSchoolDetailLine;
