import { Component } from 'react';
import { ProgressBar } from '@instructure/ui';

import ResultCell from './ResultCell';

const defaultRelevanceMaxValue = 1;

export default class RelevanceCell extends Component {
  /* need to identify this component as "Cell" so that it can be a valid child of a
      Table.Row: https://github.com/instructure/instructure-ui/issues/673#issuecomment-895250758
  */
  static displayName = 'Cell';

  render = () => (
    <ResultCell key={this.props.key} display="block" field="relevance">
      {typeof this.props.value === 'number' && (
        <ProgressBar
          size="small"
          valueMax={this.props.maxValue || defaultRelevanceMaxValue}
          valueNow={this.props.value}
          screenReaderLabel="relevance is {value}"
        />
      )}
    </ResultCell>
  );
}
