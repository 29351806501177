import { useState } from 'react';

import {
  Button,
  CloseButton,
  Heading,
  Modal,
  Text,
  TextArea,
  TextInput,
} from '@instructure/ui';

import { submitFeedback } from '../api';

import ExternalLink from './ExternalLink';

const FEEDBACK_EMAIL_ADDRESS = process.env.REACT_APP_FEEDBACK_EMAIL_ADDRESS;

const FeedbackModal = ({ isOpen = false, setIsOpen }) => {
  const [email, setEmail] = useState('');
  const [messageBody, setMessageBody] = useState('');

  const handleDismiss = () => setIsOpen(false);

  const handleEmailAddressChange = (event, value) => {
    // takes (event, value) params, per https://instructure.design/#TextInput
    event.preventDefault();
    setEmail(value);
  };
  const handleMessageBodyChange = event => {
    // takes only `event` param, per https://instructure.design/#TextArea
    event.preventDefault();
    setMessageBody(event.target.value);
  };

  const handleFormSubmit = async e => {
    e.preventDefault();
    await submitFeedback(email, messageBody);
    handleDismiss();
    setEmail('');
    setMessageBody('');
  };

  return (
    <div id="feedback-modal">
      <Modal
        as="form"
        defaultFocusElement={() =>
          document.getElementById('feedback-from-address-input')
        }
        mountNode={() => document.getElementById('feedback-modal')}
        open={!!isOpen} // todo: for some reason, `isOpen` is sometimes an Object; this converts into a boolean to avoid console errors
        onDismiss={handleDismiss}
        onSubmit={handleFormSubmit}
        size="medium"
        label="Modal Dialog: Contact Us"
      >
        <Modal.Header>
          <CloseButton
            color="primary"
            data-testid="feedback-modal-header-close-button-container"
            offset="small"
            onClick={handleDismiss}
            placement="end"
            screenReaderLabel="Close"
          />
          <Heading>Contact Us</Heading>
        </Modal.Header>
        <Modal.Body>
          <Text lineHeight="double">
            <p>
              We appreciate your feedback on the Syllabus Explorer! Report a bug
              or provide suggestions using the form below.
            </p>
            <p>
              For troubleshooting upload issues, please review the{' '}
              <ExternalLink
                href="https://syllabus.harvard.edu/help"
                title="Syllabus Explorer help page"
              >
                Help page→ For Instructors tab
              </ExternalLink>
              .
            </p>
            <p>
              To remove your syllabus or to request another change, please{' '}
              <ExternalLink
                href="https://icommons-tools.tlt.harvard.edu/qualtrics_taker_auth/?qtarget=aHR0cHM6Ly9oYXJ2YXJkLmF6MS5xdWFsdHJpY3MuY29tL2pmZS9mb3JtL1NWXzRIajViRTVXc0xEVHFteA=="
                title="Syllabus change request form"
              >
                submit a request using this separate form
              </ExternalLink>
              .
            </p>
          </Text>
          <TextInput
            id="feedback-to-address-input"
            interaction="readonly"
            renderLabel="To"
            defaultValue={FEEDBACK_EMAIL_ADDRESS}
          />

          <TextInput
            id="feedback-from-address-input"
            onChange={handleEmailAddressChange}
            placeholder="you@harvard.edu"
            renderLabel="From"
            value={email}
          />
          <TextArea
            autoGrow={false}
            id="feedback-message-body-input"
            label="Message"
            onChange={handleMessageBodyChange}
            placeholder="Please provide your feedback"
            resize="vertical"
            value={messageBody}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="secondary"
            data-testid="feedback-modal-footer-close-button"
            margin="0 x-small 0 0"
            onClick={handleDismiss}
          >
            Close
          </Button>
          <Button
            color="primary"
            data-testid="feedback-modal-footer-submit-button"
            type="submit"
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FeedbackModal;
