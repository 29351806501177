import { Text, View } from '@instructure/ui';

const DEFAULT_NO_RESULTS_MESSAGE = 'No results. Please try a different search.';

const NoResultsMessage = ({ messageText = DEFAULT_NO_RESULTS_MESSAGE }) => (
  <>
    {messageText ? (
      <View textAlign="center" as="div" className="no-results-msg">
        <Text wrap="break-word">{messageText}</Text>
      </View>
    ) : null}
  </>
);

export default NoResultsMessage;
