import { Component } from 'react';
import { Table } from '@instructure/ui';

export default class ResultRow extends Component {
  /* need to identify this component as "Row" so that it can be a valid child of a
      Table.Body: https://github.com/instructure/instructure-ui/issues/673#issuecomment-895250758
  */
  static displayName = 'Row';

  handleRowClick = e => {
    const clickedOnRowId = e.currentTarget.id;
    /* If onClick() handler is invalid, do not run any handlers; this allows
      user to select text (if React has to repaint, it can reset current text
      selection)
    */
    if (this.props.onClick) this.props.onClick(clickedOnRowId);
    e.preventDefault();
    e.stopPropagation();
  };

  handleKeyDown = e => {
    /* bind Enter and spacebar to same event as click; i.e. allow
      user to select the row using the keyboard; allow enter or
      space to propagate to other handlers if the current row is
      already selected, so that e.g. spacebar can still be used to
      scroll through the table */
    if (
      (e.key === 'Enter' || e.key === ' ') &&
      e.currentTarget.getAttribute('data-selected') !== 'true'
    )
      this.handleRowClick(e);
  };

  render = () => (
    <Table.Row
      key={this.props.rowId}
      onClick={this.handleRowClick}
      onKeyDown={this.handleKeyDown}
      id={this.props.rowId}
      data-selected={this.props.selected}
    >
      {this.props.children}
    </Table.Row>
  );
}
