import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Flex } from '@instructure/ui';

import CollapsedQueryDetails from './CollapsedQueryDetails';
import DepartmentDetail from './DepartmentDetail';
import DepartmentResultsList from './DepartmentResultsList';
import LeftSearchBar from './LeftSearchBar';
import ResultsHeading from './ResultsHeading';
import Search from './Search';

import { getSchoolFromDeptId, useDepartmentsFetch } from '../api';
import {
  useDepartmentDetailNavigation,
  useDepartmentSearchNavigation,
  useCourseSearchNavigation,
} from '../navigation';
import { UI_PAGE_RESULT_DETAILS, UI_PAGE_SEARCH_RESULTS } from '../ui';

const SearchDepartments = ({
  departments, // `departments` is loaded once at app startup
  departmentDocId,
  searchQuery,
  schoolLookup, //'schoolLookup' is loaded once at startup
  filterLabels,
  filters,
}) => {
  // department info is loaded once at app startup, so once the `departments`
  // prop is available it will remain so for the lifetime of the app
  const isFetchingDepartments = Object.keys(departments).length === 0;

  // state managed internally to components, mostly for UI interactions
  const [currentSearchInput] = useState(searchQuery);

  /* ui: on small screens we use the inst-ui "Page" component to foreground
  particular parts of the UI; `uiPageIndex` tracks which page should be visible
  at a given time */
  const [uiPageIndex, setUiPageIndex] = useState(
    departmentDocId ? UI_PAGE_RESULT_DETAILS : UI_PAGE_SEARCH_RESULTS,
  );

  /* ui: on small screens we use the inst-ui "Tray" component to hide away
  search and filter details unless needed; `isSearchTrayOpen` tracks state */
  const [isSearchTrayOpen, setIsSearchTrayOpen] = useState(false);

  // custom fetch hooks
  const {
    results: searchResults,
    isFetching: isFetchingSearchResults,
    error: searchError,
  } = useDepartmentsFetch(searchQuery, filters);
  if (searchError) throw searchError; // throw error for ErrorBoundary

  // custom navigation hooks
  const navigateDepartmentDetail = useDepartmentDetailNavigation({
    query: searchQuery.trim(),
    filters,
  });
  const navigateRelatedCourses = useCourseSearchNavigation({
    query: searchQuery.trim(),
    filters: {
      d: departmentDocId,
      /* prefilter the course results to see only courses for the currently
        selected department */
      deptFilter: departments[departmentDocId]?.id,
      schoolFilter: getSchoolFromDeptId(departments[departmentDocId]?.id),
      /* kick off a new course search, but retain current department search
        filter values so the user can return to this search results set */
      deptSearchDeptFilter: filters.deptFilter,
      deptSearchSchoolFilter: filters.schoolFilter,
    },
  });

  /* calling with `{}` ensures that the search query and filters will be
    sent in by the downstream UI component instead of based on the state
    tracked in this component */
  const navigateSearch = useDepartmentSearchNavigation({});

  const showResultDetail = selectedResultId => {
    setUiPageIndex(UI_PAGE_RESULT_DETAILS);
    navigateDepartmentDetail(selectedResultId);
  };

  const performSearch = (newQuery, filters) => {
    setUiPageIndex(UI_PAGE_SEARCH_RESULTS);
    setIsSearchTrayOpen(false);
    navigateSearch(newQuery.trim(), filters);
  };

  // todo: can this be validated at the router level?
  if (!searchQuery) return <Redirect to="/" />;
  return (
    <Search
      queryTrayOpen={isSearchTrayOpen}
      onQueryTrayClose={() => setIsSearchTrayOpen(false)}
      activePageIndex={uiPageIndex}
      setActivePageIndex={setUiPageIndex}
      query={
        <LeftSearchBar
          filterLabels={filterLabels}
          formDescription="School search form"
          onSubmit={performSearch}
          searchFilters={filters}
          searchQuery={currentSearchInput}
          searchType={'departments'}
          submitLabel="Search schools"
        />
      }
      results={
        <Flex direction="column" as="div" height="100%">
          <Flex.Item as="div" overflowY="hidden">
            <ResultsHeading
              heading={'Top School/Department Results for:'}
              detail={searchQuery}
            />
            <CollapsedQueryDetails
              onEdit={() => setIsSearchTrayOpen(true)}
              filterLabels={filterLabels}
              filters={filters}
            />
          </Flex.Item>
          <Flex.Item as="div" shouldShrink>
            <DepartmentResultsList
              results={searchResults}
              isFetching={isFetchingSearchResults}
              onRowClick={showResultDetail}
              selectedId={departmentDocId}
              schoolLookup={schoolLookup}
            />
          </Flex.Item>
        </Flex>
      }
      details={
        <DepartmentDetail
          onClose={() => performSearch(searchQuery, filters)}
          detail={departments[departmentDocId]}
          schoolLookup={schoolLookup}
          isLoading={isFetchingDepartments}
          onSubmitViewRelatedCourses={navigateRelatedCourses}
        />
      }
      /* signal to the <Search> component that the details panel is active
        (so it can be highlighted); Search cannot currently inspect the `details`
        prop to infer this state */
      detailActive={
        departments[departmentDocId] &&
        Object.entries(departments[departmentDocId]).length > 0
      }
    />
  );
};

export default SearchDepartments;
