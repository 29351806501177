import { ScreenReaderContent } from '@instructure/ui';

// todo/note: https://instructure.design/#SimpleSelect is probably
// better in the long run, so we don't have to maintain much code,
// but it is currently "experimental"; we may still benefit from
// encapsulating it in this component and using it, so that any API
// changes are only necessary in this single interface component
import SingleSelectExample from './SingleSelectExample';

const LeftSearchBarSelect = ({
  label,
  options = [],
  onFilterChange,
  selectedOptionId,
}) => {
  return (
    <SingleSelectExample
      label={<ScreenReaderContent>{label}</ScreenReaderContent>}
      onFilterChange={onFilterChange}
      options={options}
      placeholder={label}
      selectedOptionId={selectedOptionId}
    />
  );
};

export default LeftSearchBarSelect;
