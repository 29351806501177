import { FormFieldGroup, ScreenReaderContent } from '@instructure/ui';

const LeftSearchBarForm = ({ description, onSubmit, children }) => {
  const handleSubmit = e => {
    onSubmit(e);
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormFieldGroup
        layout="stacked"
        rowSpacing="medium"
        description={<ScreenReaderContent>{description}</ScreenReaderContent>}
        label="label"
      >
        {children}
      </FormFieldGroup>
    </form>
  );
};

export default LeftSearchBarForm;
