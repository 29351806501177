import { Text, ToggleDetails } from '@instructure/ui';
import CustomTruncateText from './CustomTruncateText';

export const MAX_QUERY_LENGTH_BEFORE_TRUNCATION = 50;

const ResultsHeadingSearchQuery = ({ children: text }) => {
  const renderText = content => (
    <CustomTruncateText charLimit={MAX_QUERY_LENGTH_BEFORE_TRUNCATION}>
      {content}
    </CustomTruncateText>
  );
  return text.length <= MAX_QUERY_LENGTH_BEFORE_TRUNCATION ? (
    renderText(text)
  ) : (
    <>
      {renderText(text)}
      <div data-testid="custom-truncate-text-toggle-details">
        <ToggleDetails summary="See full search query">
          <Text wrap="break-word">{text}</Text>
        </ToggleDetails>
      </div>
    </>
  );
};

export default ResultsHeadingSearchQuery;
