import { useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { Flex } from '@instructure/ui';
import { isAuthenticated, login } from './auth';
import ErrorBoundary from './ErrorBoundary';
import Footer from './components/Footer';
import SearchCourses from './components/SearchCourses';
import SearchDepartments from './components/SearchDepartments';
import SearchSimilarCourses from './components/SearchSimilarCourses';
import TopBar from './components/TopBar';
import Welcome from './components/Welcome';
import SyllabusResources from './components/SyllabusResources';
import Help from './components/Help';
import { getDepartments } from './api';
import {
  getDepartmentNames,
  getFilterLabels,
  getSchoolNames,
  topics,
} from './ui';

import './App.css';

const stripLoginRedirectFromUrl = (history, location, params) => {
  /* we don't want authenticated users to share a URL with loginRedirect=true
    in it, because someone loading that URL who is not yet authenticated will
    not be able to authenticate (as the app thinks there is a redirect loop),
    so overwrite history with the current URL without the loginRedirect param */
  params.delete('loginRedirect');
  history.replace({
    pathname: location.path,
    search: '?' + params.toString(),
  });
};

const TopicRedirect = () => {
  const { topicId } = useParams();
  return <Redirect to={topics[topicId] || '/'} />;
};

const PrivateRoute = ({
  children,
  authenticated,
  isContactModalOpen,
  setIsContactModalOpen,
  ...rest
}) => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const loginRedirectParam = params.get('loginRedirect');
  if (authenticated) {
    if (loginRedirectParam)
      stripLoginRedirectFromUrl(history, location, params);
    /* Include ErrorBoundary here so that if child components fail the user
    will still see the TopBar and branding, and have some recourse to provide
    feedback or seek help in the links */
    return (
      <Flex as="div" id="routes" direction="column" height="100%">
        <Flex.Item as="header">
          <TopBar
            isContactModalOpen={isContactModalOpen}
            setIsContactModalOpen={setIsContactModalOpen}
          />
        </Flex.Item>
        <Flex.Item as="main" shouldGrow shouldShrink>
          <ErrorBoundary>
            <Route {...rest}>{children}</Route>
          </ErrorBoundary>
        </Flex.Item>
        <Flex.Item as="footer">
          <Footer />
        </Flex.Item>
      </Flex>
    );
  }
  /* if we've already come from a login attempt, as identified by the presence
     of the `loginRedirect=true` URL param, and we are not recognized as being
     logged in, then we may fall into a redirect loop, so only attempt login if
     we have not attempted it yet; this should only happen if there's a serious
     error with SSO or someone shares an ephemeral link with loginRedirect=true */
  if (loginRedirectParam !== 'true') {
    login();
    return null;
  }
  stripLoginRedirectFromUrl(history, location, params);
  throw new Error('possible login redirect loop: aborting redirect to login');
};

export const Routes = ({ schools }) => {
  /* `location` contains the search params and resource IDs that determine
     what is displayed each route */
  let location = useLocation();
  let searchParams = new URLSearchParams(location.search);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  let searchFilters = {};
  [
    /* courseSearch* filters track original course search filter values when browsing
      similar course results (launched from course search), so that when returning to
      course search view we can retain the user's search preferences / original result set */
    'courseSearchDeptFilter',
    'courseSearchSchoolFilter',
    'courseSearchWhenFilter',
    /* deptSearch* filters track original department search filter values when browsing
      related course results (launched from department search), so that when returning to
      department search view we can retain the user's search preferences / original result set */
    'deptSearchDeptFilter',
    'deptSearchSchoolFilter',
    /* these filters control the search bar values and results for the current view */
    'deptFilter',
    'schoolFilter',
    'whenFilter',
  ].forEach(f => {
    if (searchParams.has(f)) {
      searchFilters[f] = searchParams.get(f);
    }
  });

  /* state managed by URL params (via `history` navigation)
     course detail doc ID is used to track the currently highlighted course
     in the course or similar course search views */
  const courseDetailDocId = (searchParams.get('c') || '').trim();
  /* courseCode is used in similar courses search; it is the course code for
     the course that was used as the basis for the search (the original course);
     it's simply a convenience that avoids having to make an extra backend
     API call to get the course code, as it's the only piece of information
     needed about the original course when in similar course search view (aside
     from the doc ID of the original course) */
  const courseCode = (searchParams.get('ccode') || '').trim();
  /* department doc ID is used to track the currently highlighted department
     in the department search view */
  const departmentDocId = (searchParams.get('d') || '').trim();
  const searchQuery = (searchParams.get('q') || '').trim();
  const myHarvardCourseId = (searchParams.get('course_id') || '').trim();
  const schoolLookup = getSchoolNames(schools);
  const departments = getDepartments(schools);

  /* produces the complete filter label lookup required by the UI */
  const filterLabels = getFilterLabels({
    deptLabels: getDepartmentNames(departments),
    schoolLabels: schoolLookup,
  });

  const authenticated = isAuthenticated();

  return (
    <Switch>
      <PrivateRoute
        authenticated={authenticated}
        exact
        path="/"
        isContactModalOpen={isContactModalOpen}
        setIsContactModalOpen={setIsContactModalOpen}
        render={props => (
          <Welcome
            {...props}
            setIsContactModalOpen={setIsContactModalOpen}
            myHarvardCourseId={myHarvardCourseId}
          />
        )}
      />
      <PrivateRoute
        path="/search/courses/:courseId/similar/"
        authenticated={authenticated}
        isContactModalOpen={isContactModalOpen}
        setIsContactModalOpen={setIsContactModalOpen}
        render={props => (
          <SearchSimilarCourses
            {...props}
            courseCode={courseCode}
            courseDetailDocId={courseDetailDocId}
            filterLabels={filterLabels}
            filters={searchFilters}
            searchQuery={searchQuery}
          />
        )}
      />
      <PrivateRoute
        path="/search/courses/"
        authenticated={authenticated}
        isContactModalOpen={isContactModalOpen}
        setIsContactModalOpen={setIsContactModalOpen}
        render={props => (
          <SearchCourses
            {...props}
            courseDetailDocId={courseDetailDocId}
            departmentDocId={departmentDocId}
            departments={departments}
            filterLabels={filterLabels}
            filters={searchFilters}
            schoolLookup={schoolLookup}
            searchQuery={searchQuery}
          />
        )}
      />
      <PrivateRoute
        path="/search/departments/"
        authenticated={authenticated}
        isContactModalOpen={isContactModalOpen}
        setIsContactModalOpen={setIsContactModalOpen}
        render={props => (
          <SearchDepartments
            {...props}
            departmentDocId={departmentDocId}
            departments={departments}
            filterLabels={filterLabels}
            filters={searchFilters}
            schoolLookup={schoolLookup}
            searchQuery={searchQuery}
          />
        )}
      />
      <PrivateRoute
        exact
        path="/resources"
        authenticated={authenticated}
        isContactModalOpen={isContactModalOpen}
        setIsContactModalOpen={setIsContactModalOpen}
        render={props => <SyllabusResources {...props} />}
      />

      <PrivateRoute
        exact
        path="/help"
        authenticated={authenticated}
        isContactModalOpen={isContactModalOpen}
        setIsContactModalOpen={setIsContactModalOpen}
        render={props => <Help {...props} />}
      />
      <Route
        exact
        path="/topics/:topicId"
        render={props => <TopicRedirect {...props} />}
      />
      <Redirect to="/" />
    </Switch>
  );
};
// utility function to return relative path for a view/route
export const searchPath = (searchType, params) =>
  `/search/${searchType}/?` + new URLSearchParams(params).toString();

export default Routes;
