import { useEffect, useState } from 'react';

import { Button, Flex, Table, Text } from '@instructure/ui';

import LoadingSpinner from './LoadingSpinner';
import NoResultsMessage from './NoResultsMessage';

// cf https://stackoverflow.com/a/59837035/3526824
const getUniqueId = prefix => {
  return Math.random()
    .toString(36)
    .replace('0.', prefix || '');
};

const ResultsList = ({
  children,
  fetchMoreRows = null,
  hasMoreResults = false,
  headerInfo,
  isFetching = false,
  isPaginated = true,
  noResultsMessageText,
  showResultsCount = false,
  size,
  tableCaption = 'Search results',
}) => {
  const [showResultsSummary, setShowResultsSummary] = useState(false);
  const [tableElement, setTableElement] = useState(null);
  const scrollableContainerId = getUniqueId('scrollable-container-');

  useEffect(() => {
    if (tableElement) {
      // allow all rows to be reached using the tab key
      tableElement.querySelectorAll('tbody > tr').forEach(el => {
        el.setAttribute('tabindex', '0');
      });
    }
  }, [
    children, // whenever children is updated the table is redrawn, so we need to watch that too
    tableElement,
  ]);

  useEffect(() => {
    /* We only want to show results summary if:
    1. there are no results, or
    2. after the results have been displayed in the table;
    Otherwise, the table may not be rendered yet before we show the results summary.
    */
    if (
      (!hasMoreResults && !isFetching && size === 0) ||
      tableElement?.querySelectorAll('tbody > tr')?.length > 0
    ) {
      setShowResultsSummary(true);
    } else {
      setShowResultsSummary(false);
    }
  }, [
    children, // whenever children is updated the table is redrawn, so we need to watch that too
    hasMoreResults,
    isFetching,
    setShowResultsSummary,
    size,
    tableElement,
  ]);

  return isFetching && size === 0 ? (
    LoadingSpinner
  ) : (
    <Flex as="div" direction="column">
      <Flex.Item as="div" id={scrollableContainerId}>
        <Table
          caption={tableCaption}
          elementRef={el => setTableElement(el)}
          layout="auto"
        >
          <Table.Head>
            <Table.Row>
              {headerInfo.map(header => (
                <Table.ColHeader
                  id={header.id}
                  key={header.id}
                  sortDirection={header.sort}
                  width={header.width}
                >
                  {header.text}
                </Table.ColHeader>
              ))}
            </Table.Row>
          </Table.Head>
          {/* Note: @instructure/ui-table Table components expect all direct children
          to be Table.X components, so children should not be wrapped in custom React
          components, but instead be a list of <Table.Row>s
          */}
          <Table.Body>{children}</Table.Body>
        </Table>
      </Flex.Item>
      {showResultsSummary && children?.length === 0 && (
        <Flex.Item as="div" id="no-results-message-container">
          <NoResultsMessage messageText={noResultsMessageText} />
        </Flex.Item>
      )}
      {showResultsSummary && showResultsCount && children?.length !== 0 && (
        <Flex.Item as="div" id="results-count-container" shouldGrow>
          {hasMoreResults ? (
            <div>
              <br></br>
              <Text as="div" size="small" fontStyle="italic">
                Loaded {size} result{size > 1 ? 's' : null}.
              </Text>
              <Button
                size="small"
                color="secondary"
                margin="medium none none none"
                type="submit"
                onClick={fetchMoreRows}
                interaction={isFetching ? 'disabled' : 'enabled'}
              >
                {isFetching ? 'Loading...' : 'Load More'}
              </Button>
            </div>
          ) : (
            <div>
              <br></br>
              <Text as="div" size="small" fontStyle="italic">
                {isPaginated ? 'No more results to display. ' : null}
                Loaded {size} result
                {size > 1 ? 's' : null}.
              </Text>
            </div>
          )}
        </Flex.Item>
      )}
    </Flex>
  );
};

export default ResultsList;
