import { useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Text } from '@instructure/ui-text';
import { mergeDeep } from '@instructure/ui-utils';

import LeftSearchBarQuery from './LeftSearchBarQuery';
import LeftSearchBarSelect from './LeftSearchBarSelect';
import LeftSearchBarForm from './LeftSearchBarForm';
import LeftSearchBarButton from './LeftSearchBarSubmit';
import { searchPath } from '../Routes';
import { validFilters } from '../navigation';

const getOptions = displayObj =>
  Object.entries(displayObj).map(([k, v]) => ({
    id: k,
    label: v,
  }));

const sortLabels = (a, b) => {
  if (a.id === 'any') return -1;
  if (b.id === 'any') return 1;
  return a.label.localeCompare(b.label);
};

const LeftSearchBar = ({
  filterOnly = false,
  filterLabels,
  formDescription = 'Course search form',
  onSubmit,
  searchQuery,
  searchFilters,
  searchType = 'courses',
  submitLabel = 'Search courses',
}) => {
  const [currentSearchInput, setCurrentSearchInput] = useState(searchQuery);
  const [currentDeptFilter, setCurrentDeptFilter] = useState(
    searchFilters.deptFilter,
  );
  const [currentSchoolFilter, setCurrentSchoolFilter] = useState(
    searchFilters.schoolFilter,
  );
  const [currentWhenFilter, setCurrentWhenFilter] = useState(
    searchFilters.whenFilter,
  );

  var deptFilterOptions = getOptions(filterLabels.deptFilter.display).sort(
    sortLabels,
  );
  var schoolFilterOptions = getOptions(filterLabels.schoolFilter.display).sort(
    sortLabels,
  );
  /*
   when filter options are ordered temporally vs. alphabetically, so we do not
   sort using sortLabels
  */
  const whenFilterOptions = getOptions(filterLabels.whenFilter.display);

  /*
    Reset filters to route-provided values on route change
    Note: useLayoutEffect() is employed instead of useEffect() to reduce lag between
    loading a new route and updating the filter dropdown/select input values
  */
  useLayoutEffect(() => {
    setCurrentDeptFilter(searchFilters.deptFilter);
    setCurrentSchoolFilter(searchFilters.schoolFilter);
    setCurrentSearchInput(searchQuery);
    setCurrentWhenFilter(searchFilters.whenFilter);
  }, [searchFilters, searchQuery]);

  /*
    Restrict department options to only those that belong to the currently
    selected school.
  */
  if (![undefined, 'any'].includes(currentSchoolFilter)) {
    deptFilterOptions = deptFilterOptions.filter(
      option =>
        option.id === 'any' || option.id.startsWith(currentSchoolFilter),
    );
  }

  /*
    Upon selecting a school, if the current department selection does not
    belong to that school then reset it.
  */
  useLayoutEffect(() => {
    if (![undefined, 'any'].includes(currentSchoolFilter)) {
      if (
        currentDeptFilter &&
        !currentDeptFilter.startsWith(currentSchoolFilter)
      ) {
        setCurrentDeptFilter(undefined);
      }
    }
  }, [currentSchoolFilter, currentDeptFilter]);

  return (
    <LeftSearchBarForm
      onSubmit={() => {
        onSubmit(currentSearchInput, {
          deptFilter: currentDeptFilter !== 'any' ? currentDeptFilter : null,
          schoolFilter:
            currentSchoolFilter !== 'any' ? currentSchoolFilter : null,
          whenFilter:
            currentWhenFilter !== 'anytime' ? currentWhenFilter : null,
        });
      }}
      description={formDescription}
    >
      {!filterOnly && (
        <LeftSearchBarQuery
          onChange={setCurrentSearchInput}
          searchQuery={currentSearchInput}
        />
      )}
      {searchType === 'courses' && (
        <LeftSearchBarSelect
          label="Select Term"
          onFilterChange={setCurrentWhenFilter}
          options={whenFilterOptions}
          selectedOptionId={currentWhenFilter}
        />
      )}
      <LeftSearchBarSelect
        label="Select School"
        onFilterChange={setCurrentSchoolFilter}
        options={schoolFilterOptions}
        selectedOptionId={currentSchoolFilter}
      />
      <LeftSearchBarSelect
        label="Select Department"
        onFilterChange={setCurrentDeptFilter}
        options={deptFilterOptions}
        selectedOptionId={currentDeptFilter}
      />
      <LeftSearchBarButton
        label={submitLabel}
        searchQuery={currentSearchInput}
      />{' '}
      {searchType === 'courses' && !filterOnly && (
        <Text>
          <Link
            to={searchPath('departments', {
              q:
                currentSearchInput.trim() !== ''
                  ? currentSearchInput.trim()
                  : searchQuery,
              ...validFilters(
                mergeDeep(searchFilters, {
                  // note: whenFilter not used for department search, so no reason to include in a department search link
                  whenFilter: null,
                  schoolFilter: currentSchoolFilter,
                }),
              ),
            })}
          >
            Or Search Schools
          </Link>
        </Text>
      )}
      {searchType === 'departments' && !filterOnly && (
        <Text>
          <Link
            to={searchPath('courses', {
              q: currentSearchInput,
              ...validFilters(
                mergeDeep(searchFilters, {
                  // note: whenFilter not used for department search, so no useful value to send to course search
                  whenFilter: null,
                  schoolFilter: currentSchoolFilter,
                }),
              ),
            })}
          >
            Or Search Courses
          </Link>
        </Text>
      )}
    </LeftSearchBarForm>
  );
};

export default LeftSearchBar;
