import { useHistory } from 'react-router-dom';

import { searchPath } from './Routes';

/* ensures that only filters defined with a valid string are included in navigation changes */
export const validFilters = filters => {
  let filterSubset = {};
  for (const f in filters) {
    if (![null, undefined, ''].includes(filters[f]))
      filterSubset[f] = filters[f];
  }
  return filterSubset;
};

export const useCourseDetailNavigation = ({ query, filters }) => {
  let history = useHistory();
  return courseId =>
    history.push(
      searchPath('courses', {
        c: courseId,
        q: query,
        ...validFilters(filters),
      }),
    );
};

export const useCourseSearchNavigation = ({ query, filters }) =>
  useSearchNavigation({
    query: query,
    filters,
    searchType: 'courses',
  });

export const useDepartmentSearchNavigation = ({ query, filters }) =>
  useSearchNavigation({
    query: query,
    filters,
    searchType: 'departments',
  });

export const useSearchNavigation = ({ query, filters, searchType }) => {
  let history = useHistory();

  return (newQuery, newFilters) =>
    history.push(
      searchPath(searchType, {
        q: query || newQuery,
        ...validFilters(filters || newFilters),
      }),
    );
};

export const useSimilarCourseDetailNavigation = ({
  courseId,
  courseCode,
  query,
  filters,
}) => {
  let history = useHistory();
  return detailCourseId =>
    history.push(
      searchPath(`courses/${courseId}/similar`, {
        q: query,
        c: detailCourseId,
        ccode: courseCode,
        ...validFilters(filters),
      }),
    );
};

export const useSimilarCourseSearchNavigation = ({
  courseId,
  courseCode,
  query,
  filters,
}) => {
  let history = useHistory();
  return (newQuery, newFilters) =>
    history.push(
      searchPath(`courses/${courseId}/similar`, {
        q: query || newQuery,
        ccode: courseCode,
        ...validFilters(filters || newFilters),
      }),
    );
};

export const useDepartmentDetailNavigation = ({ query, filters }) => {
  let history = useHistory();
  return deptId =>
    history.push(
      searchPath('departments', {
        d: deptId,
        q: query,
        ...validFilters(filters),
      }),
    );
};

export const useNavigateTo = (relativePath, urlParams) => {
  let history = useHistory();
  return () => history.push(relativePath, urlParams);
};
