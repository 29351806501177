import { Pages } from '@instructure/ui';

// todo: Warning: [Page] A default focusable element is required or focus will be lost
const MobileResults = ({
  results,
  details,
  activePageIndex,
  setActivePageIndex,
}) => (
  <Pages
    activePageIndex={activePageIndex}
    onPageIndexChange={newPageIndex => setActivePageIndex(newPageIndex)}
  >
    <Pages.Page>
      <div id="results">{results}</div>
    </Pages.Page>
    <Pages.Page>
      <div id="detail-view">{details}</div>
    </Pages.Page>
  </Pages>
);

export default MobileResults;
