import { useState } from 'react';

import { Flex, InlineList, Text } from '@instructure/ui';

const Footer = () => {
  const [currentYear] = useState(new Date().getFullYear());
  return (
    <Flex justifyItems="center" id="footer">
      <Flex.Item id="footer-links" textAlign="center" shouldGrow>
        <InlineList delimiter="pipe">
          <InlineList.Item>
            <Text color="secondary">©{currentYear} Harvard University</Text>
          </InlineList.Item>
          <InlineList.Item>
            <a
              href="https://vpal.harvard.edu/syllabusexplorer-acceptableuse"
              rel="noreferrer noopener"
              target="_blank"
              title="Acceptable Use Policy"
            >
              Acceptable Use Policy
            </a>
          </InlineList.Item>
          <InlineList.Item>
            <a
              href="http://www.harvard.edu/privacy-statement"
              rel="noreferrer noopener"
              target="_blank"
              title="The Harvard University Privacy Policy"
            >
              Privacy Statement
            </a>
          </InlineList.Item>
          <InlineList.Item>
            <a
              href="http://accessibility.harvard.edu"
              rel="noreferrer noopener"
              target="_blank"
              title="The Harvard University Accessibility Policy"
            >
              Accessibility
            </a>
          </InlineList.Item>
        </InlineList>
      </Flex.Item>
    </Flex>
  );
};

export default Footer;
